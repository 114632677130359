input[type="button"],
input[type="submit"],
input[type="reset"],
input[type="file"]::-webkit-file-upload-button,
button {
	border-color: transparent;
}

.btns {}

.btn {
	line-height: 2.357142857;
	color: #FFF;
	margin: auto 8px;
	padding: 13px 33px;
	display: inline-block;
	line-height: 1;
	border-color: transparent;
	-webkit-appearance: none;
	&:first-child {
		margin-left: 0;
	}
	&--primary {
		border-radius: 2px;
		background: $dark-red;
		@include fs-pro-700;
		font-size: 1.4em;
		text-transform: uppercase;
		letter-spacing: 1px;
		transition: .25s all;
		&:hover,
		&:focus {
			color: $dark-red;
			background: #FFF;
		}
	}
	&--text {
		@include fs-pro-600;
		font-size: 1.6em;
		letter-spacing: 0.01px;
		text-decoration: underline;
		padding: 13px 10px;
		&:hover,
		&:focus {
			text-decoration: none;
			color: #FFF;
		}
	}
}
