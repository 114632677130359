.tabs-wrapper {
	max-width: 1200px;
	padding-left: 30px;
	padding-right: 30px;
	margin: 40px auto auto;
	position: relative;
	z-index: 9999;
	.tabs {
		margin: 25px 0 0;
		padding: 0;
		list-style: none;
		li {
			margin: 0 0px 10px;
			@media #{$tablet-up} {
				display: inline-block;
				margin: 0 5px 10px;
			}
			&:first-child {
				margin-left: 0;
			}
			&:last-child {
				margin-right: 0;
			}
			&.active {
				a {
					background: $dark-red;
				}
			}
		}
		a {
			color: #ffffff;
			font-family: "freight-sans-pro";
			font-style: normal;
			font-weight: 700;
			font-size: 1.3em;
			line-height: 1.615384615;
			letter-spacing: 0.2px;
			text-transform: uppercase;
			padding: 10px 15px;
			display: block;
			border: 2px solid $dark-red;
			transition: all 0.25s;
			background: #000;
			&:hover,
			&:focus {
				background: $dark-red;
			}
		}
	}
}

#user-login,
#user-pass {
	max-width: 1200px;
	padding-left: 30px;
	padding-right: 30px;
	margin: auto auto 30px;
	input[type="text"],
	input[type="password"] {
		border-radius: 2px;
		border: 1px solid #adadad;
		background-color: #fff;
		color: #000;
		width: 100%;
		height: 43px;
		font-size: 1.4em;
		@include fs-pro;
		@include placeholder {
			font-style: italic;
			color: #9b9b9b;
		}
		@media #{$medium-down} {
			box-sizing: border-box;
			text-align: center;
			margin-bottom: 10px;
		}
		@media #{$large-up} {
			height: 25px;
			max-width: 385px;
		}
	}
	input[type="submit"] {
		@extend .btn;
		@extend .btn--primary;
		margin-left: 0;
	}
	label {
		@include chunk;
		color: #000;
		padding: 4px 0 0;
		margin: 25px 0 10px;
		font-size: 2.1em;
		text-align: left;
		display: block;
	}
	.description {
		display: block;
		padding: 5px 0;
		font-size: 1.2em;
		margin-bottom: 15px;
		color: #000;
	}
}
