.author {
	//padding:16px 0 0;
	margin-bottom: 40px;
	@media #{$tablet-up} {
		display: flex;
		justify-content: space-between;
	}
	&__image {
		display: block;
		margin: auto auto 32px;
		img {
			display: block;
			border-radius: 50%;
			margin: auto;
		}
		@media #{$tablet-up} {
			width: 164px;
			margin: 15px 20px 0 0;
		}
		@media #{$large-up} {
			margin: 15px 65px 0 0;
		}
	}
	&__title {
		color: #ffffff;
		@include chunk;
		font-size: 3.1em;
		line-height: 1.709677419;
		letter-spacing: 0.02px;
		margin: 0 0 4px;
	}
	&__content {
		text-align: center;
		@media #{$tablet-up} {
			text-align: left;
			max-width: 558px;
			width: 100%;
		}
		p {
			opacity: 0.85;
			color: rgba(#fff, .85);
			@include ft-pro-500;
			font-size: 2em;
			line-height: 1.6;
			letter-spacing: 0.2px;
			margin: 0 0 10px;
			@media #{$tablet-up} {
				margin: 0 0 7px;
			}
			a {
				color: #FFF;
				text-decoration: underline;
				&:hover,
				&:focus {
					text-decoration: none;
				}
			}
		}
	}
	&__twitter {
		@include fs-pro-600;
		font-size: 1.6em;
		line-height: 1.4375;
		letter-spacing: 0.01px;
		color: #FFF;
		a {
			color: #FFF;
		}
		.social {
			color: $dark-red;
			margin-right: 10px;
			font-size: 2em;
			display: inline-block;
			vertical-align: middle;
		}
		&:hover,
		&:focus {
			text-decoration: underline;
			color: #FFF;
		}
	}
}
