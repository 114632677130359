$logo-width:160px;
$logo-mobile-width: 109px;
nav.nav {
	@media #{$large-up} {
		margin: 0 auto -20px;
	}
	&.wrap {
		padding-left: 0;
		padding-right: 0;
	}
}

.nav {
	position: relative;
	z-index: 10;
	&__logo {
		background:url(#{$img}national-interest-logo.png) no-repeat 50% 50%;
		display: block;
		width: $logo-mobile-width;
		height: 53px;
		background-size: 100%;
		@media #{$large-up} {
			width: $logo-width;
			;
			height: 76px;
		}
	}
	&__item {
		@media #{$large-up} {
			display: inline-block;
			margin-left: 5px;
			position: relative;
			z-index: 2;
			&:hover,
			&:focus {
				.nav__submenu {
					display: block;
					opacity: 1;
				}
			}
		}
		&:after {
			content: '';
			display: inline-block;
			vertical-align: middle;
			width: 1px;
			height: 14px;
			background: #b22a39;
			.nav__list--secondary & {
				background-color: rgba(#fff, .3);
			}
		}
		&:last-child {
			&:after {
				display: none;
			}
		}
	}
	&__lists {
		text-align: right;
	}
	&__list {
		&--main {
			margin-right: 28px;
			.nav__item {
				@media #{$large-up} {
					padding-bottom: 21px;
					&:hover {
						&>.nav__link {
							color: rgba(255, 255, 255, 0.67);
							&:hover,
							&:focus {
								color: #FFF;
							}
						}
					}
				}
			}
			.nav__link {
				@include ft-pro-500;
				font-size: 2.2em;
				/* Text style for "Foreign Po" */
				letter-spacing: 0.25px;
				@media #{$large-up} {
					font-size: 1.7em;
				}
				@media (min-width: 1100px) {
					font-size: 1.8em;
				}
				@media (min-width:1175px) {
					padding: 5px 11px;
					font-size: 1.8em;
				}
			}
		}
		&--secondary {
			margin-bottom: 26px;
			margin-right: 11px;
			margin-top: -3px;
			.nav__link {
				@include fs-pro-600;
				font-size: 1.6em;
				letter-spacing: 0.2px;
				@media #{$large-up} {
					font-size: 1.5em;
				}
			}
		}
	}
	&__link {
		color: #FFF;
		padding: 5px 5px;
		transition: all .25s;
		@media #{$xlarge-up} {
			padding: 5px 10px;
		}
		&:hover,
		&:focus {
			background: $dark-red;
			color: #FFF;
		}
	}
	&__contain {
		display: flex;
		justify-content: space-between;
		padding-top: 20px;
		background: $body-bg-color;
		padding-left: 30px;
		padding-right: 30px;
	}
	&__wrap--desktop {
		display: none;
		@media #{$large-up} {
			display: flex;
			justify-content: flex-end;
			width:calc(100% - #{$logo-width});
		}
	}
	&__mag {
		margin-top: -20px;
		position: relative;
		&__link {
			position: relative;
			display: block;
			margin-bottom: -26px;
			transition: all .15s;
			border: 2px solid transparent;
			box-sizing: border-box;
			&:hover,
			&:focus {
				border-color: $dark-red;
			}
			img {
				display: block;
			}
		}
		&__text {
			background: $dark-red;
			color: #FFF;
			text-transform: uppercase;
			display: block;
			text-align: center;
			padding: 8px 3px;
			font-size: 1.1em;
			font-weight: bold;
			width: 100%;
			box-sizing: border-box;
			transition: all .25s;
			position: absolute;
			bottom: 0px;
		}
		&__subscribe {
			position: absolute;
			width: 100%;
			bottom: -26px;
			&:hover,
			&:focus {
				.nav__mag__text {
					background: #000;
				}
			}
		}
	}
}

.nav-mobile {
	background: #000;
	position: absolute;
	z-index: 2;
	width: 100%;
	left: 0;
	padding: 0 30px 20px;
	box-sizing: border-box;
	@media #{$large-up} {
		display: none;
	}
	&__button {
		width: 48px;
		height: 48px;
		background: url(#{$img}hamburger.svg) no-repeat 50% 50%;
		margin: 6px -8px 0 0;
		border: none;
		&--open {
			background-image:url(#{$img}hamburger-close.svg);
		}
		@media #{$large-up} {
			display: none;
		}
	}
	&__toggle {
		display: none;
		max-height: 0;
		transition: all .25s;
		overflow: hidden;
		&:focus {
			outline: none;
		}
		&--block {
			display: block;
		}
		&--open {
			max-height: 2000px;
		}
	}
	.nav__item {
		border-bottom: 1px solid rgba(#969696, 0.25);
		padding-bottom: 7px;
		position: relative;
		&:after {
			display: none;
		}
	}
	.nav__link {
		display: block;
		width: 90%;
		padding: 23px 2px 11px;
		&:hover,
		&:focus {
			background-color: transparent;
			text-decoration: underline;
		}
	}
	.nav__list--main {
		margin: 20px 0 0 0;
	}
	.nav__list--secondary {
		margin: 34px 0 20px;
		.nav__item {
			border-bottom: none;
			padding-bottom: 9px;
		}
		.nav__link {
			padding: 10px 2px
		}
	}
	&__subscribe {
		border-radius: 2px;
		background-color: $dark-red;
		color: #FFF;
		display: block;
		text-align: center;
		@include fs-pro-700;
		text-transform: uppercase;
		font-size: 1.5em;
		line-height: 1;
		letter-spacing: 1px;
		padding: 16px 6px;
		transition: all .25s;
		&:hover,
		&:focus {
			background: #FFF;
			color: $dark-red;
		}
	}
}

.nav__submenu {
	display: none;
	max-height: 0;
	transition: all .25s;
	overflow: hidden;
	&--block {
		display: block;
	}
	&--open {
		max-height: 2000px;
	}
	@media #{$large-up} {
		opacity: 0;
		transition: 0.25s all;
		position: absolute;
		max-height: 1000px;
		min-width: 160px;
		background-color: #1f1f1f;
		text-align: left;
		left: 50%;
		transform: translateX(-50%);
		overflow: visible;
		margin-top: 21px;
		&:before {
			content: '';
			display: block;
			position: absolute;
			top: -10px;
			left: 50%;
			transform: translateX(-50%);
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 7.5px 10px 7.5px;
			border-color: transparent transparent #1f1f1f transparent;
		}
	}
}

.nav__toggle {
	background:url(#{$img}plus.png) no-repeat 50% 50%;
	width: 48px;
	height: 48px;
	position: absolute;
	border: none;
	right: -10px;
	top: 9px;
	z-index: 2;
	&--open {
		background-image:url(#{$img}minus.png)
	}
}

.submenu {
	margin-top: 21px;
	@media #{$large-up} {
		margin-top: 0;
		padding: 21px 0;
	}
	&__item {}
	&__link {
		@include fs-pro-600;
		color: #b1b1b1;
		font-size: 1.7em;
		padding: 13px 2px;
		display: block;
		transition: all .25s;
		&:hover,
		&:focus {
			color: #FFF;
			text-decoration: underline;
		}
		@media #{$large-up} {
			padding: 13px 19px;
			color: #FFF;
			font-size: 1.6em;
			&:hover,
			&:focus {
				color: #FFF;
				text-decoration: none;
				background-color: #b22a39;
				;
			}
		}
	}
}

.nav-search {
	input {
		&[type="text"],
		&[type="search"] {
			color: #ffffff;
			@include ft-pro-500-i;
			font-size: 1.5em;
			letter-spacing: 0.2px;
			background: transparent;
			border: none;
			width: 50px;
			transition: all .25s;
			@include placeholder {
				color: #ffffff;
			}
			@media #{$large-up} {
				&:focus,
				&.not-empty {
					width: 140px;
				}
			}
		}
		.nav-mobile & {
			width: calc(100% - 48px);
			padding: 0.625em 1em 0.625em .25em;
		}
	}
	&__submit {
		background:url(#{$img}search.svg) no-repeat 50% 50%;
		background-size: 14px;
		border: none;
		display: inline-block;
		width: 20px;
		height: 16px;
		margin-right: 24px;
		.nav-mobile & {
			margin-right: 0;
		}
	}
}
