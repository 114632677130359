.listing{
    border-bottom:1px solid rgba(#969696, .3);
    padding:15px 0 18px;
    @media #{$large-up}{
        padding:21px 0;
    }
    &--image{
        padding:0;
        border-bottom:none;
        position: relative;

        @media #{$large-up}{
            margin-bottom:8px;
            padding:0;
        }
        .listing__title{
            @include chunk;
            font-size: 2.0em;
            line-height: 1.24;
            letter-spacing: 0.02px;
            margin:0px 0 5px;
        }
    }
    &__image{
        position: relative;
        &:after{
            content:'';
            position: absolute;
            width:100%;
            height:100%;
            top:0;
            left:0;
            background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
            z-index: 2;
        }
    }
    &__content{
        position: absolute;
        z-index: 3;
        bottom:34px;
        left:20px;
        right:30px;

        @media #{$large-up}{
            bottom:28px;
        }
    }
    &__title{
        @include fs-pro-700;
        font-size:1.8em;
        line-height: 1.28;
        color:#FFF;
        margin:0 0 8px;
        a{
            color:#FFF;
            &:hover, &:focus{
                text-decoration: underline;
            }
        }
    }
}
