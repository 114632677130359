.gallery {
	@include clearfix;
	// @include grid-column($grid-columns);

	&-item {
		box-sizing: border-box;
		display: block;
		float: left;
		text-align: left;
		margin: 0;
		padding: 0 1em 0 0;

		a {
			display: inline-block;
			max-width: 100%;

			img {
				display: block;
				transition: filter 0.2s ease-in;
				backface-visibility: hidden;

				&:hover,
				&:focus {
					filter: opacity(60%);
				}
			}
		}
	}

	&-columns {
		@for $i from 1 through 9 {
			&-#{$i} {
				.gallery-item {
					width: unquote("#{(100 / $i)}%");
				}
			}
		}
	}

	&-caption {
		font-size: .8em;
		padding: 0 0.5em;
	}
}
