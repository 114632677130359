//
// COLOR VARIABLES
//
// - Cool
// - Warm
// - Gray Scale
//

// Cool

$aqua:  #7FDBFF;
$blue:  #0074D9;
$navy:  #001F3F;
$teal:  #39CCCC;
$green: #2ECC40;
$olive: #3D9970;
$lime:  #01FF70;

// Warm

$yellow:  #FFDC00;
$orange:  #FF851B;
$red:     #FF4136;
$fuchsia: #F012BE;
$purple:  #B10DC9;
$maroon:  #85144B;

// Gray Scale

$white:  #FFFFFF;
$silver: #DDDDDD;
$gray:   #AAAAAA;
$black:  #111111;

