@import "../vendor/colors-sass/sass/variables";
$html-bg-color: $black;
$html-text-color: $silver;
$body-bg-color: #000;
$body-text-color: #FFF;
$darker-text-color: darken($body-text-color, 15%);
$lighter-text-color: lighten($body-text-color, 15%);
$link-text-color: $blue;
$link-text-color-hover: $navy;
$line-gray: $silver;
$line-light-gray: lighten($silver, 15%);
$h1-text-color: #000;
$h2-text-color: #000;
$h3-text-color: #000;
$h4-text-color: #000;
$h5-text-color: #000;
$h6-text-color: #000;


$dark-red:#b22a39;
