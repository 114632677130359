.flex {
	display: flex;
	flex-direction: column;
	@media #{$small-up2} {
		flex-direction: row;
		justify-content: space-between;
		align-items: stretch;
		flex-wrap: wrap;
		&--2 {
			>* {
				width: 48.095238095%
			}
		}
	}
}

.flex-l {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	@media #{$large-up} {
		flex-direction: row;
		&__2-3 {
			max-width: 840px;
			width: 100%;
			+.flex-l__1-3 {
				padding-left: 30px;
			}
			&.--narrow {
				max-width: 789px;
			}
		}
		&__1-3 {
			max-width: 329px;
		}
	}
}
