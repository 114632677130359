.ad {
	background: #edecee;
	&--728 {
		padding: 4px;
		text-align: center; //display: none;
		@media #{$large-up} {
			display: inline-block;
			max-width: 1260px;
			margin: auto;
			position: relative;
			left: 50%;
			transform: translateX(-50%);
		}
	}
	&--324 {
		padding: 12px; // margin:-12px -30px 40px;
		text-align: center;
		margin-left: -30px;
		margin-right: -30px;
		+.featured-articles__title {
			margin-top: 40px;
		}
	}
	&--336 {
		text-align: center;
		padding: 4px;
		display: inline-block;
	}
	&--162 {
		text-align: center;
		display: inline-block;
		padding: 4px;
		position: relative;
		left: 50%;
		transform: translateX(-50%);
		&.--ad-right {
			left: auto;
			transform: translateX(0);
			@media #{$large-down} {
				margin-right: auto;
			}
			@media #{$tablet-down} {
				float: none;
				margin-left: auto;
				margin-right: auto;
				padding: 4px;
				display: none;
			}
		}
		@media #{$large-up} {
			display: inline-block;
		}
		.touchevents & {
			display: none;
		}
	}
	&--center {
		background: none;
		text-align: center;
		max-width: 100%;
		overflow: hidden;
		>div:first-child {
			display: inline-block;
			min-width: 550px;
			box-sizing: border-box;
			// iframe {
			// 	background: #edecee;
			// 	padding: 4px 4px 0;
			// }
		}
		@media #{$tablet-down} {
			margin-left: auto;
			margin-right: auto;
			>div:first-child {
				padding: 4px;
				min-width: 320px;
			}
		}

		// @media (min-width: 900px) {
		// 	float: left;
		// 	padding: 20px;
		// 	max-width: 600px;
		// 	min-width: 300px;

		// 	&:nth-of-type(even) {
		// 		float: right;
		// 	}
		// }

		// &--alt {
		// 	@media #{$tablet-down} {
		// 		width:calc(100% + 60px);
		// 		max-width:calc(100% + 60px);
		// 		margin-left:-30px;
		// 	}
		// }

	}
	&--hide-mobile {
		@media #{$tablet-down} {
			display: none;
		}
	}
	&--hide-tablet-down {
		.touchevents & {
			@media screen and (max-width: 1024px) {
				display: none;
			}
		}
	}
	&--leaderboard {
		background: none;
	}

	// &--precontent {
	// 	@media (min-width: 900px) {
	// 		float: right;
	// 		padding: 20px;
	// 		max-width: 600px;
	// 		min-width: 300px;
	// 	}
	// }

}

.--ad-left {
	margin: 25px auto;
	@media #{$medium-up} {
		float: left;
		margin: 25px 65px 0px 0;
	}
	@media #{$xlarge-up} {
		margin-left: -101px;
	}
}

.featured-articles__wrap {
	+.ad--162 {
		margin-top: 75px;
	}
	+.ad--324,
	+.ad--162+.ad--324 {
		margin-top: 45px;
	}
}

@media screen and (min-width: 600px) {
	.mobile--only {
		display: none;
	}
}
.mobile--only > div {
	margin: 0 auto;
}

