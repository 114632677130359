@charset "UTF-8";/* merriweather-300 - latin *//* merriweather-300italic - latin *//* merriweather-regular - latin *//* merriweather-italic - latin *//* merriweather-700 - latin *//* merriweather-700italic - latin *//* merriweather-900 - latin *//* merriweather-900italic - latin *//* source-sans-pro-regular - latin *//* source-sans-pro-italic - latin *//* source-sans-pro-600 - latin *//* source-sans-pro-700 - latin */.chunk {
  font-family: 'Chunk';
  font-weight: normal;
  font-style: normal;
}

.fs-pro {
  font-family: Helvetica, Arial, sans-serif;
  font-style: normal;
}

.fs-pro-med,.fs-pro-500 {
  font-family: Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
}

.fs-pro-med-i,.fs-pro-500-i {
  font-family: Helvetica, Arial, sans-serif;
  font-style: italic;
  font-weight: 400;
}

.fs-pro-semi,.fs-pro-600 {
  font-family: Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
}

.fs-pro-semi,.fs-pro-700 {
  font-family: Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
}

.fd-pro-semi,.fd-pro-700 {
  font-family: 'freight-display-pro';
  font-style: normal;
  font-weight: 700;
}

.ft-pro {
  font-family: "Georgia", "Palatino Linotype", "Book Antiqua", "Palatino", "Times New Roman", Times, serif;
}

.ft-pro-light-i,.ft-pro-300-i {
  font-family: "Georgia", "Palatino Linotype", "Book Antiqua", "Palatino", "Times New Roman", Times, serif;
  font-weight: 300;
  font-style: italic;
}

.ft-pro-light,.ft-pro-300 {
  font-family: "Georgia", "Palatino Linotype", "Book Antiqua", "Palatino", "Times New Roman", Times, serif;
  font-weight: 300;
  font-style: normal;
}

.ft-pro-med,.ft-pro-500 {
  font-family: "Georgia", "Palatino Linotype", "Book Antiqua", "Palatino", "Times New Roman", Times, serif;
  font-weight: 400;
  font-style: normal;
}

.ft-pro-med-i,.ft-pro-500-i {
  font-family: "Georgia", "Palatino Linotype", "Book Antiqua", "Palatino", "Times New Roman", Times, serif;
  font-weight: 400;
  font-style: italic;
}

.ft-pro-semi,.ft-pro-600 {
  font-family: "Georgia", "Palatino Linotype", "Book Antiqua", "Palatino", "Times New Roman", Times, serif;
  font-weight: 700;
  font-style: normal;
}

.ft-pro-black,.ft-pro-900 {
  font-family: "Georgia", "Palatino Linotype", "Book Antiqua", "Palatino", "Times New Roman", Times, serif;
  font-weight: 900;
  font-style: normal;
}/* @font-face kit by Fonts2u (http://www.fonts2u.com) */body {
  background-color: #FFF;
  color: #FFF;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Avenir Next", "Avenir", "Segoe UI", "Lucida Grande", "Helvetica Neue", "Helvetica", "Fira Sans", "Roboto", "Noto", "Droid Sans", "Cantarell", "Oxygen", "Ubuntu", "Franklin Gothic Medium", "Century Gothic", "Liberation Sans", sans-serif;
  font-size: 62.5%;
  line-height: 1;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

hr {
  border: 0;
  border-top: 1px solid #DDDDDD;
  margin: 1 auto;
}

a {
  color: #0074D9;
  cursor: pointer;
  text-decoration: none;
}

a:hover {
  color: #001F3F;
}

h1,h2,h3,h4,h5,h6,p,ul,ol {
  margin: 1 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,.h1 {
  color: #000;
  font: 5.6em/1.27 system-ui, -apple-system, BlinkMacSystemFont, "Avenir Next", "Avenir", "Segoe UI", "Lucida Grande", "Helvetica Neue", "Helvetica", "Fira Sans", "Roboto", "Noto", "Droid Sans", "Cantarell", "Oxygen", "Ubuntu", "Franklin Gothic Medium", "Century Gothic", "Liberation Sans", sans-serif;
  font-family: 'Chunk';
  font-weight: normal;
  font-style: normal;
}

h2,.h2 {
  color: #000;
  font: 600 4.5em/1.38 system-ui, -apple-system, BlinkMacSystemFont, "Avenir Next", "Avenir", "Segoe UI", "Lucida Grande", "Helvetica Neue", "Helvetica", "Fira Sans", "Roboto", "Noto", "Droid Sans", "Cantarell", "Oxygen", "Ubuntu", "Franklin Gothic Medium", "Century Gothic", "Liberation Sans", sans-serif;
  font-family: 'Chunk';
  font-weight: normal;
  font-style: normal;
}

h3,.h3 {
  color: #000;
  font: 3.25em/1.5 system-ui, -apple-system, BlinkMacSystemFont, "Avenir Next", "Avenir", "Segoe UI", "Lucida Grande", "Helvetica Neue", "Helvetica", "Fira Sans", "Roboto", "Noto", "Droid Sans", "Cantarell", "Oxygen", "Ubuntu", "Franklin Gothic Medium", "Century Gothic", "Liberation Sans", sans-serif;
  font-family: 'Chunk';
  font-weight: normal;
  font-style: normal;
}

h4,.h4 {
  color: #000;
  font: 2.725em/1.5 system-ui, -apple-system, BlinkMacSystemFont, "Avenir Next", "Avenir", "Segoe UI", "Lucida Grande", "Helvetica Neue", "Helvetica", "Fira Sans", "Roboto", "Noto", "Droid Sans", "Cantarell", "Oxygen", "Ubuntu", "Franklin Gothic Medium", "Century Gothic", "Liberation Sans", sans-serif;
  font-family: 'Chunk';
  font-weight: normal;
  font-style: normal;
}

h5,.h5 {
  color: #000;
  font: bold 2.125em/1.5 system-ui, -apple-system, BlinkMacSystemFont, "Avenir Next", "Avenir", "Segoe UI", "Lucida Grande", "Helvetica Neue", "Helvetica", "Fira Sans", "Roboto", "Noto", "Droid Sans", "Cantarell", "Oxygen", "Ubuntu", "Franklin Gothic Medium", "Century Gothic", "Liberation Sans", sans-serif;
  font-family: 'Chunk';
  font-weight: normal;
  font-style: normal;
}

h6,.h6,legend {
  color: #000;
  font: 2em/1.625 system-ui, -apple-system, BlinkMacSystemFont, "Avenir Next", "Avenir", "Segoe UI", "Lucida Grande", "Helvetica Neue", "Helvetica", "Fira Sans", "Roboto", "Noto", "Droid Sans", "Cantarell", "Oxygen", "Ubuntu", "Franklin Gothic Medium", "Century Gothic", "Liberation Sans", sans-serif;
  font-family: 'Chunk';
  font-weight: normal;
  font-style: normal;
}

img {
  height: auto;
  max-width: 100%;
}

figure {
  margin: 0;
}

figcaption {
  font-size: 0.875em;
  line-height: 1.4;
  padding: 1em;
  text-align: left;
}

nav ol,nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

nav li {
  margin: 0;
}

dl {
  margin: 1 auto;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 1;
  margin-left: 0;
}

ul,ol {
  margin: 1 0;
  padding-left: 1;
}

li {
  margin: 0.5 0;
}

table {
  border-collapse: collapse;
  margin: 1 auto;
  text-align: left;
  width: 100%;
}

caption {
  margin-bottom: 0.625em;
}

th,td {
  padding: 0.625em 1em;
}

tr.odd td {
  background: #FFFFFF !important;
}

tr.even td {
  background: white !important;
}

th {
  background: #111111;
  color: #DDDDDD;
}

th:first-child {
  border-left: 1px solid #111111;
}

th:last-child {
  border-right: 1px solid #111111;
}

tbody {
  box-shadow: 0 0 10px rgba(217, 217, 217, 0.05);
}

tbody td:first-child {
  border-left: 1px solid white;
}

tbody td:last-child {
  border-right: 1px solid white;
}

tbody tr:last-child td {
  border-bottom: 1px solid white;
}

tbody tr:nth-child(odd) td {
  background: #FFFFFF;
}

tbody tr:nth-child(even) td {
  background: white;
}

tfoot {
  color: white;
  font-size: 0.875em;
}

fieldset {
  border: 0;
  border-top: 1px solid #DDDDDD;
  margin: 1 0;
  padding: 0;
}

legend {
  margin-left: 0.5em;
  padding: 0 0.5em;
}

label {
  cursor: pointer;
  display: inline-block;
}

[type='color'],[type='date'],[type='datetime'],[type='datetime-local'],[type='email'],[type='month'],[type='number'],[type='password'],[type='search'],[type='tel'],[type='text'],[type='time'],[type='url'],[type='week'],input:not([type]),textarea {
  border: 1px solid white;
  padding: 0.625em 1em;
}

a,input[type="button"],input[type="submit"],input[type="reset"],button {
  cursor: pointer;
}

.svg-hide {
  display: none;
}

.sr-only,.a11y,.element-invisible {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
}

.sr-only-focusable:active,.sr-only-focusable:active,.a11y-focusable:active,.a11y-focusable:active,.element-invisible-focusable:active,.element-invisible-focusable:active {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  -webkit-clip-path: none;
  clip-path: none;
}

.ad {
  background: #edecee;
}

.ad--728 {
  padding: 4px;
  text-align: center;
}

.ad--324 {
  padding: 12px;
  text-align: center;
  margin-left: -30px;
  margin-right: -30px;
}

.ad--324 + .featured-articles__title {
  margin-top: 40px;
}

.ad--336 {
  text-align: center;
  padding: 4px;
  display: inline-block;
}

.ad--162 {
  text-align: center;
  display: inline-block;
  padding: 4px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.ad--162.--ad-right {
  left: auto;
  transform: translateX(0);
}

.touchevents .ad--162 {
  display: none;
}

.ad--center {
  background: none;
  text-align: center;
  max-width: 100%;
  overflow: hidden;
}

.ad--center > div:first-child {
  display: inline-block;
  min-width: 550px;
  box-sizing: border-box;
}

.ad--leaderboard {
  background: none;
}

.--ad-left {
  margin: 25px auto;
}

.featured-articles__wrap + .ad--162 {
  margin-top: 75px;
}

.featured-articles__wrap + .ad--324,.featured-articles__wrap + .ad--162 + .ad--324 {
  margin-top: 45px;
}

.mobile--only > div {
  margin: 0 auto;
}

.article,.hp-featured {
  color: #fff;
  position: relative;
}

.article picture,.hp-featured picture {
  position: relative;
  display: block;
}

.article picture:before,.hp-featured picture:before {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  content: "";
  height: 50%;
  width: 100%;
  top: 50%;
  position: absolute;
}

.article__img,.hp-featured__img {
  display: block;
  width: 100%;
}

.article__title,.hp-featured__title {
  font-family: 'Chunk';
  font-weight: normal;
  font-style: normal;
  font-size: 2.8em;
  font-weight: 400;
  line-height: 1.36;
  letter-spacing: 0.02px;
  margin: 13px 0 7px;
}

.article__title a,a .article__title,.hp-featured__title a,a
.hp-featured__title {
  color: #fff;
  text-decoration: none;
}

.article__title a:hover,.article__title a:focus,a .article__title:hover,a .article__title:focus,.hp-featured__title a:hover,.hp-featured__title a:focus,a
.hp-featured__title:hover,a
.hp-featured__title:focus {
  text-decoration: underline;
}

.article__summary,.hp-featured__summary {
  font-family: "Georgia", "Palatino Linotype", "Book Antiqua", "Palatino", "Times New Roman", Times, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.8em;
  line-height: 1.66;
  letter-spacing: 0.2px;
  color: rgba(255, 255, 255, 0.9);
  margin: 7px 0 20px;
}

.article--img,.hp-featured--img {
  position: relative;
  margin-bottom: 15px;
}

.article--img .article__link,.hp-featured--img .article__link {
  position: relative;
  z-index: 2;
  display: block;
}

.article--img .article__link:after,.hp-featured--img .article__link:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
}

.article--img .article__title,.hp-featured--img .article__title {
  position: absolute;
  bottom: 20px;
  left: 18px;
  right: 18px;
  font-size: 2.3em;
  z-index: 3;
}

.article--img img,.hp-featured--img img {
  display: block;
  width: 100%;
}

.term-title {
  font-family: 'Chunk';
  font-weight: normal;
  font-style: normal;
  font-size: 3em;
  font-weight: 400;
  line-height: 1.36;
  letter-spacing: 0.02px;
  margin: 13px 0 7px;
  color: #000;
}

.term-title a {
  color: #b22a39;
}

.term-title a:hover,.term-title a:focus {
  text-decoration: underline;
}

.hp-featured img {
  display: block;
  width: 100%;
}

.hp-featured__content {
  margin-bottom: 49px;
}

.hp-featured__content {
  z-index: 2;
  padding: 0 30px;
  position: relative;
  margin-top: -42%;
}

.hp-featured__category {
  font-family: Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.4em;
  line-height: 1;
  text-transform: uppercase;
  display: block;
  margin-bottom: 13px;
  position: relative;
}

.--bg-light .article {
  color: #2e3a44;
}

.--bg-light .article .article__title,.--bg-light .article .article__title a {
  color: #000;
}

.--bg-light .article .article__summary {
  color: inherit;
}

.--bg-light .article .meta a {
  color: #b22a39;
}

.article--grid {
  margin-bottom: 26px;
}

.article--grid .article__title {
  font-size: 2.8em;
  line-height: 1.357142857;
  padding-right: 5px;
}

.article--grid .article__summary {
  font-size: 1.8em;
  line-height: 1.722222222;
}

.article--grid .meta__author {
  font-size: 1.7em;
}

.article--flex {
  border-bottom: 1px solid rgba(150, 150, 150, 0.25);
  padding-bottom: 27px;
  margin-bottom: 24px;
}

.article--flex .article__img img {
  max-width: 150%;
  width: 100%;
}

.article--cta {
  padding: 28px 0;
}

.article--cta:last-child {
  margin-bottom: 0;
  border-bottom: none;
}

.article--cta .article__title {
  max-width: 530px;
  font-size: 2.8em;
}

.article-grid {
  margin-bottom: 20px;
}

.archives {
  background: #fff;
  padding: 70px 0;
  padding: 36px 0 57px;
}

.archives .listing--image {
  margin-bottom: 2.316667em;
}

.archives__title {
  font-family: 'Chunk';
  font-weight: normal;
  font-style: normal;
  color: #000;
  font-size: 2.7em;
  text-align: center;
  line-height: 1;
  margin: 0 0 45px;
}

.archives__title span {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

.archives__title span:before,.archives__title span:after {
  content: "•";
  color: #b22a39;
  font-size: 1em;
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.archives__title span:before {
  left: -20px;
}

.archives__title span:after {
  right: -20px;
}

.author {
  margin-bottom: 40px;
}

.author__image {
  display: block;
  margin: auto auto 32px;
}

.author__image img {
  display: block;
  border-radius: 50%;
  margin: auto;
}

.author__title {
  color: #ffffff;
  font-family: 'Chunk';
  font-weight: normal;
  font-style: normal;
  font-size: 3.1em;
  line-height: 1.709677419;
  letter-spacing: 0.02px;
  margin: 0 0 4px;
}

.author__content {
  text-align: center;
}

.author__content p {
  opacity: 0.85;
  color: rgba(255, 255, 255, 0.85);
  font-family: "Georgia", "Palatino Linotype", "Book Antiqua", "Palatino", "Times New Roman", Times, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 2em;
  line-height: 1.6;
  letter-spacing: 0.2px;
  margin: 0 0 10px;
}

.author__content p a {
  color: #FFF;
  text-decoration: underline;
}

.author__content p a:hover,.author__content p a:focus {
  text-decoration: none;
}

.author__twitter {
  font-family: Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.6em;
  line-height: 1.4375;
  letter-spacing: 0.01px;
  color: #FFF;
}

.author__twitter a {
  color: #FFF;
}

.author__twitter .social {
  color: #b22a39;
  margin-right: 10px;
  font-size: 2em;
  display: inline-block;
  vertical-align: middle;
}

.author__twitter:hover,.author__twitter:focus {
  text-decoration: underline;
  color: #FFF;
}

.blogs .listing__image img {
  width: 100%;
}

.blogs__title {
  color: #ffffff;
  font-family: "Georgia", "Palatino Linotype", "Book Antiqua", "Palatino", "Times New Roman", Times, serif;
  font-weight: 700;
  font-style: normal;
  font-size: 3.1em;
  line-height: 1.870967742;
  letter-spacing: 0.02px;
}

.blogs__title:after {
  content: '';
  height: 2px;
  width: 27px;
  background: #b22a39;
  display: block;
}

.blogs__title a {
  color: #FFF;
}

.blogs__title a:hover,.blogs__title a:focus {
  text-decoration: underline;
}

.blogs__all {
  padding: 25px 0;
  display: block;
  font-family: Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #ffffff;
  font-size: 1.4em;
}

.blogs__all:hover,.blogs__all:focus {
  text-decoration: underline;
  color: #fff;
}

.bx-wrap {
  max-width: 385px;
  margin: auto;
}

.blog-list-bx {
  padding-bottom: 37px;
}

.body-overflow {
  position: relative;
}

input[type="button"],input[type="submit"],input[type="reset"],input[type="file"]::-webkit-file-upload-button,button {
  border-color: transparent;
}

.btn,.webform-client-form .webform-submit,.webform-client-form input[type="submit"],.views-exposed-widget input[type="submit"],#user-login input[type="submit"],#user-pass input[type="submit"] {
  line-height: 2.357142857;
  color: #FFF;
  margin: auto 8px;
  padding: 13px 33px;
  display: inline-block;
  line-height: 1;
  border-color: transparent;
  -webkit-appearance: none;
}

.btn:first-child,.webform-client-form .webform-submit:first-child,.webform-client-form input:first-child[type="submit"],.views-exposed-widget input:first-child[type="submit"],#user-login input:first-child[type="submit"],#user-pass input:first-child[type="submit"] {
  margin-left: 0;
}

.btn--primary,.webform-client-form .webform-submit,.webform-client-form input[type="submit"],.views-exposed-widget input[type="submit"],#user-login input[type="submit"],#user-pass input[type="submit"] {
  border-radius: 2px;
  background: #b22a39;
  font-family: Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.4em;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: .25s all;
}

.btn--primary:hover,.webform-client-form .webform-submit:hover,.webform-client-form input:hover[type="submit"],.views-exposed-widget input:hover[type="submit"],#user-login input:hover[type="submit"],#user-pass input:hover[type="submit"],.btn--primary:focus,.webform-client-form .webform-submit:focus,.webform-client-form input:focus[type="submit"],.views-exposed-widget input:focus[type="submit"],#user-login input:focus[type="submit"],#user-pass input:focus[type="submit"] {
  color: #b22a39;
  background: #FFF;
}

.btn--text {
  font-family: Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.6em;
  letter-spacing: 0.01px;
  text-decoration: underline;
  padding: 13px 10px;
}

.btn--text:hover,.btn--text:focus {
  text-decoration: none;
  color: #FFF;
}

.bx-wrapper {
  position: relative;
  margin-bottom: 60px;
  padding: 0;
  *zoom: 1;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.bx-wrapper img {
  max-width: 100%;
  display: block;
  width: 100%;
}

.bxslider {
  margin: 0;
  padding: 0;
}

ul.bxslider {
  list-style: none;
}

.bx-viewport {
  -webkit-transform: translateZ(0);
}

.bx-wrapper .bx-pager,.bx-wrapper .bx-controls-auto {
  position: absolute;
  bottom: -30px;
  width: 100%;
}

.bx-wrapper .bx-loading {
  min-height: 50px;
  background: url(../img//bx/bx_loader.gif) center center no-repeat #ffffff;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2000;
}

.bx-wrapper .bx-pager {
  text-align: center;
  font-size: .85em;
  font-family: Arial;
  font-weight: bold;
  color: #666;
  padding-top: 20px;
}

.bx-wrapper .bx-pager.bx-default-pager a {
  background: transparent;
  text-indent: -9999px;
  display: block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  outline: 0;
  border-radius: 50%;
  border: 1px solid #4d4d4d;
}

.bx-wrapper .bx-pager.bx-default-pager a:hover,.bx-wrapper .bx-pager.bx-default-pager a.active {
  background: #FFFF;
  border-color: #FFF;
}

.bx-wrapper .bx-pager.bx-default-pager a:focus {
  border-color: #b22a39;
}

.bx-wrapper .bx-pager-item,.bx-wrapper .bx-controls-auto .bx-controls-auto-item {
  display: inline-block;
  vertical-align: bottom;
  *zoom: 1;
  *display: inline;
}

.bx-wrapper .bx-pager-item {
  font-size: 0;
  line-height: 0;
}

.bx-wrapper .bx-prev {
  left: 10px;
  background: url("/assets/img/bx/controls.png") no-repeat 0 -32px;
}

.bx-wrapper .bx-prev:hover,.bx-wrapper .bx-prev:focus {
  background-position: 0 0;
}

.bx-wrapper .bx-next {
  right: 10px;
  background: url("/assets/img/bx/controls.png") no-repeat -43px -32px;
}

.bx-wrapper .bx-next:hover,.bx-wrapper .bx-next:focus {
  background-position: -43px 0;
}

.bx-wrapper .bx-controls-direction a {
  position: absolute;
  top: 50%;
  margin-top: -16px;
  outline: 0;
  width: 32px;
  height: 32px;
  text-indent: -9999px;
  z-index: 9999;
}

.bx-wrapper .bx-controls-direction a.disabled {
  display: none;
}

.bx-wrapper .bx-controls-auto {
  text-align: center;
}

.bx-wrapper .bx-controls-auto .bx-start {
  display: block;
  text-indent: -9999px;
  width: 10px;
  height: 11px;
  outline: 0;
  background: url("/assets/img/bx/controls.png") -86px -11px no-repeat;
  margin: 0 3px;
}

.bx-wrapper .bx-controls-auto .bx-start:hover,.bx-wrapper .bx-controls-auto .bx-start.active,.bx-wrapper .bx-controls-auto .bx-start:focus {
  background-position: -86px 0;
}

.bx-wrapper .bx-controls-auto .bx-stop {
  display: block;
  text-indent: -9999px;
  width: 9px;
  height: 11px;
  outline: 0;
  background: url("/assets/img/bx/controls.png") -86px -44px no-repeat;
  margin: 0 3px;
}

.bx-wrapper .bx-controls-auto .bx-stop:hover,.bx-wrapper .bx-controls-auto .bx-stop.active,.bx-wrapper .bx-controls-auto .bx-stop:focus {
  background-position: -86px -33px;
}

.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-pager {
  text-align: left;
  width: 80%;
}

.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-controls-auto {
  right: 0;
  width: 35px;
}

.bx-wrapper .bx-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  background: #666;
  background: rgba(80, 80, 80, 0.75);
  width: 100%;
}

.bx-wrapper .bx-caption span {
  color: #fff;
  font-family: Arial;
  display: block;
  font-size: .85em;
  padding: 10px;
}

.bx-wrapper .bx-controls-direction a {
  display: none;
}

.bx-wrapper .bx-pager {
  bottom: -47px;
}

.archives .bx-wrapper .bx-pager.bx-default-pager a {
  border: 1px solid #d1d1d1;
}

.archives .bx-wrapper .bx-pager.bx-default-pager a:hover,.archives .bx-wrapper .bx-pager.bx-default-pager a.active {
  background: #b22a39;
  border-color: #b22a39;
}

.archives .bx-wrapper .bx-pager.bx-default-pager a:focus {
  border-color: #b22a39;
}

.category {
  text-align: center;
  padding-bottom: 44px;
}

.category__title {
  color: #ffffff;
  font-family: "Georgia", "Palatino Linotype", "Book Antiqua", "Palatino", "Times New Roman", Times, serif;
  font-weight: 700;
  font-style: normal;
  font-size: 3.1em;
  line-height: 1.870967742;
  letter-spacing: 0.02px;
}

.category__title:after {
  content: '';
  width: 27px;
  height: 2px;
  background: #b22a39;
  margin: auto;
  display: block;
}

.category p {
  font-family: "Georgia", "Palatino Linotype", "Book Antiqua", "Palatino", "Times New Roman", Times, serif;
  font-weight: 400;
  font-style: normal;
  color: #d9d9d9;
  font-size: 2em;
  line-height: 1.6;
  letter-spacing: 0.2px;
  margin-bottom: 26px;
}

.category p a {
  color: #FFF;
  text-decoration: underline;
}

.category p a:hover,.category p a:focus {
  text-decoration: none;
}

.article-detail {
  padding: 20px 0;
}

.detail-hero {
  background-image: none !important;
  background: #FFF;
}

.detail-hero__img,.detail-hero img {
  display: block;
  max-width: 1260px;
  width: 100%;
  margin: auto;
}

.detail {
  color: #050607;
  max-width: 896px;
  margin: auto;
  position: relative;
}

.detail__title {
  color: #000000;
  font-family: 'Chunk';
  font-weight: normal;
  font-style: normal;
  font-size: 3.8em;
  line-height: 1;
  letter-spacing: 0.02px;
  margin: 19px 0px 13px;
}

.detail__title--large {
  display: none;
}

.detail__sub {
  color: inherit;
  font-family: "Georgia", "Palatino Linotype", "Book Antiqua", "Palatino", "Times New Roman", Times, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 2.1em;
  line-height: 1.619047619;
  letter-spacing: 0.2px;
  border: 1px solid rgba(150, 150, 150, 0.25);
  border-width: 1px 0;
  margin: 0 0 25px;
  padding: 20px 0 26px;
}

.detail__sub a {
  color: #b22a39;
}

.detail__sub a:hover,.detail__sub a:focus {
  text-decoration: underline;
}

.meta .detail__sub a {
  color: #b22a39;
}

.meta .detail__sub a:hover,.meta .detail__sub a:focus {
  text-decoration: underline;
}

.detail__content {
  border-bottom: 1px solid rgba(150, 150, 150, 0.25);
  padding-bottom: 46px;
  margin-bottom: 31px;
}

.detail__content a {
  color: #0074D9;
}

.detail__content a:hover,.detail__content a:focus {
  text-decoration: underline;
}

.detail__content p {
  color: inherit;
  font-family: "Georgia", "Palatino Linotype", "Book Antiqua", "Palatino", "Times New Roman", Times, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.9em;
  line-height: 1.631578947;
  letter-spacing: 0.2px;
}

.detail__content p strong {
  font-weight: 900;
}

.detail__content > p.flfc:first-child:first-letter {
  font-family: 'Chunk';
  font-weight: normal;
  font-style: normal;
  color: #b22a39;
  font-size: 5.789473684em;
  text-transform: uppercase;
  float: left;
  line-height: .99;
  margin: -5px 18px -6px 0;
}

.pullquote {
  padding: 0;
  margin: 54px 0 44px;
}

.pullquote:before {
  content: '';
  width: 186px;
  height: 5px;
  background: #b22a39;
  display: block;
}

.pullquote cite {
  color: #414141;
  font-family: Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2.1em;
}

.pullquote p {
  color: #000000;
  font-family: 'Chunk';
  font-weight: normal;
  font-style: normal;
  font-size: 2.4em;
  line-height: 1.666666667;
  margin: 0.8em 0;
}

.pullquote p cite {
  font-size: .875em;
}

.details__related {
  margin-bottom: 43px;
}

.details__related__title {
  color: #242424;
  font-family: 'Chunk';
  font-weight: normal;
  font-style: normal;
  font-size: 2.1em;
  line-height: 1.571428571;
  letter-spacing: -0.1px;
  margin: 0 0 14px;
}

.details__related__title:before {
  content: '';
  width: 100%;
  height: 5px;
  background: #b22a39;
  display: block;
  margin-bottom: 18px;
}

.details__related__title + .featured {
  border-top: 1px solid rgba(150, 150, 150, 0.25);
  padding-top: 8px;
}

.details__related a {
  color: #000;
}

.details__related .featured {
  margin-bottom: 2px;
  border-color: transparent;
}

.details__related .featured:last-child {
  border-bottom: 1px solid rgba(150, 150, 150, 0.25);
  padding-bottom: 8px;
}

.details__related .featured__title {
  color: #000;
}

.recommended {
  border: 1px solid rgba(150, 150, 150, 0.25);
  border-width: 1px 0;
  margin: 38px 0 50px;
  padding: 0;
}

.recommended p {
  font-family: "Georgia", "Palatino Linotype", "Book Antiqua", "Palatino", "Times New Roman", Times, serif;
  font-weight: 300;
  font-style: italic;
  font-size: 2.4em;
  color: #585f69;
  margin: 0.7em 0;
}

.recommended p a {
  font-family: 'Chunk';
  font-weight: normal;
  font-style: normal;
  color: #000;
  font-size: .958333333em;
  line-height: 1.47826087;
  font-style: normal;
  display: block;
  margin-top: 10px;
}

.post-pagination {
  border: 5px solid rgba(150, 150, 150, 0.25);
  border-width: 5px 0;
  margin-bottom: 40px;
}

.post-pagination.--bg-light {
  padding: 0;
}

.meta--detail {
  margin-bottom: 41px;
}

.meta--detail .meta__author {
  font-size: 1.8em;
}

.meta--detail .meta__author a {
  color: #b22a39;
}

.meta--detail .meta__author a .social {
  font-size: 1.277777778em;
  position: relative;
  top: 2px;
  left: 5px;
  margin-right: 20px;
}

.meta--detail .meta__author__divide {
  margin: auto 15px auto 5px;
}

.featured-articles {
  margin-bottom: 26px;
  margin-top: 28px;
}

.featured-articles.wrap,#sliding-popup .featured-articles.popup-content {
  padding-bottom: 26px;
  margin-top: 0;
  z-index: 2;
}

.featured-articles__title {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  text-align: center;
  margin: 0;
  font-family: 'Chunk';
  font-weight: normal;
  font-style: normal;
  color: #ffffff;
  font-size: 1.9em;
  font-weight: 400;
  line-height: 1.368421053;
  margin-bottom: 10px;
  padding-bottom: 16px;
}

.featured-articles__title--light {
  background: #000;
  padding: 16px 0;
  margin-bottom: 0;
}

.featured-articles__title--small {
  font-size: 1.4em;
  border: none;
  margin-bottom: -4px;
}

.featured-articles__title span {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

.featured-articles__title span:before,.featured-articles__title span:after {
  content: "•";
  color: #b22a39;
  font-size: 0.7em;
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.featured-articles__title span:before {
  left: -20px;
}

.featured-articles__title span:after {
  right: -20px;
}

.featured {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  max-width: 328px;
}

.featured:last-child {
  border-bottom: none;
}

.featured__link {
  color: #fff;
  display: flex;
  align-items: center;
  padding: 15px 3px;
}

.popular .featured__link {
  padding: 29px 3px 24px;
}

.featured__link:hover .featured__title,.featured__link:focus .featured__title {
  text-decoration: underline;
}

.featured__title {
  color: #ffffff;
  font-family: Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.5em;
  line-height: 1.53;
  letter-spacing: 0.01px;
  width: 66.23%;
  margin: 0;
}

.featured__image {
  display: block;
  width: 22.15%;
  min-width: 72px;
  margin-right: 27px;
}

.featured__image img {
  display: block;
}

.popular .featured__image {
  width: 70px;
  min-width: 1px;
  margin-right: 21px;
}

.featured__count {
  display: block;
  position: relative;
  width: 70px;
  height: 70px;
  text-align: center;
  color: #fff;
  background: #b22a39;
  font-family: 'Chunk';
  font-weight: normal;
  font-style: normal;
  font-size: 3.2em;
  line-height: 1;
}

.featured__count span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  margin-top: 3px;
}

.featured--light {
  border-color: rgba(150, 150, 150, 0.25);
}

.featured--light .featured__title,.featured--light .featured__link {
  color: #000;
}

.featured__all {
  color: #000000;
  font-family: Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.4em;
  line-height: 1.857142857;
  display: block;
  margin-top: 20px;
  width: 100%;
}

.--bg-dark .featured__all {
  color: #fff;
}

.featured__all:hover,.featured__all:focus {
  text-decoration: underline;
}

.featured__all--border {
  padding-top: 20px;
  margin-top: 0;
  border-top: 1px solid rgba(150, 150, 150, 0.25);
}

.--bg-dark {
  background: #000;
}

.recently-popular {
  padding: 45px 0 60px;
  background: #fff;
}

.title-bleed {
  margin: 0;
  font-family: 'Chunk';
  font-weight: normal;
  font-style: normal;
  font-size: 2.2em;
  position: relative;
  color: #000;
  margin-bottom: 48px;
}

.title-bleed:before {
  content: "";
  width: 21px;
  height: 4px;
  background: #b22a39;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(-29px);
}

.flex {
  display: flex;
  flex-direction: column;
}

.flex-l {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.footer > .wrap,#sliding-popup .footer > .popup-content {
  position: relative;
  z-index: 2;
}

.footer .wrap,.footer #sliding-popup .popup-content,#sliding-popup .footer .popup-content {
  background: #000;
}

.footer__flex {
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;
}

.footer__wrap {
  display: flex;
  justify-content: space-between;
}

.footer__mag {
  position: relative;
  z-index: 2px;
}

.footer__links__wrap,.footer__secondary,.footer__main {
  display: flex;
  justify-content: space-between;
}

.footer__list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer__secondary {
  width: 32.203389831%;
}

.footer__secondary .footer__link {
  color: #ffffff;
  font-family: Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.3em;
  line-height: 1.615384615;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  padding: 5px 0;
  margin: 4px 0 10px;
  display: block;
}

.footer__secondary .footer__link:hover,.footer__secondary .footer__link:focus {
  text-decoration: underline;
}

.footer__secondary .footer__column:last-child {
  margin-left: 40px;
}

.footer .footer__title {
  color: #ffffff;
  font-family: 'Chunk';
  font-weight: normal;
  font-style: normal;
  font-size: 2.4em;
  line-height: 1.666666667;
  margin: -2px 0 9px;
}

.footer .footer__title:after {
  content: "";
  height: 2px;
  width: 27px;
  background: #b22a39;
  display: block;
}

.footer__main {
  width: 64.971751412%;
  padding-left: 3%;
}

.footer__main .footer__column {
  border-left: 1px solid rgba(150, 150, 150, 0.3);
  padding-left: 35px;
  width: 25%;
  margin-right: 4%;
}

.footer__main .footer__column:last-child {
  margin-right: 0;
}

.footer__main .footer__link {
  color: rgba(255, 255, 255, 0.7);
  font-family: Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.4em;
  letter-spacing: 0.22px;
  display: block;
  padding: 5px 0;
  margin-bottom: 6px;
}

.footer__main .footer__link:focus,.footer__main .footer__link:hover {
  text-decoration: underline;
  color: #fff;
}

.newsletter {
  padding-top: 20px;
  border-top: 1px solid rgba(150, 150, 150, 0.3);
}

.newsletter__cfni img {
  display: block;
  max-width: 221px;
}

.newsletter__form {
  display: block;
  padding-top: 38px;
}

.newsletter fieldset {
  border: none;
  width: 100%;
}

.newsletter__legend {
  border: none;
  font-family: 'Chunk';
  font-weight: normal;
  font-style: normal;
  color: #fff;
  padding: 0;
  margin: 0;
  font-size: 2.1em;
  padding-top: 4px;
  text-align: center;
  margin-bottom: 17px;
}

.newsletter__input {
  border-radius: 2px;
  border: 1px solid #adadad;
  background-color: #fff;
  color: #000;
  width: 100%;
  height: 43px;
  font-size: 1.4em;
  font-family: Helvetica, Arial, sans-serif;
  font-style: normal;
}

.newsletter__input::-webkit-input-placeholder {
  font-style: italic;
  color: #9b9b9b;
}

.newsletter__input:-moz-placeholder {
  font-style: italic;
  color: #9b9b9b;
}

.newsletter__input::-moz-placeholder {
  font-style: italic;
  color: #9b9b9b;
}

.newsletter__input:-ms-input-placeholder {
  font-style: italic;
  color: #9b9b9b;
}

.social {
  font-family: "Socialico";
}

.footer__sub .wrap,.footer__sub #sliding-popup .popup-content,#sliding-popup .footer__sub .popup-content {
  background-color: #191919;
}

.footer__sub p {
  color: #ababab;
  font-family: Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.5em;
  line-height: 1.866666667;
  letter-spacing: 0.2px;
  text-align: center;
}

.footer__sub p a {
  color: #fff;
  text-decoration: underline;
}

.footer__sub p a:hover,.footer__sub p a:focus {
  text-decoration: none;
}

.footer__sub__links {
  margin: auto auto 31px;
  padding: 0;
  list-style: none;
  text-align: center;
}

.footer__sub__item {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-right: 30px;
}

.footer__sub__item:last-child {
  margin-right: 0;
}

.footer__sub__item:before {
  content: "";
  height: 13px;
  width: 1px;
  background: rgba(150, 150, 150, 0.3);
  position: absolute;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
}

.footer__sub__link {
  color: #ababab;
  font-family: Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.3em;
  line-height: 2.153846154;
  letter-spacing: 0.2px;
}

.footer__sub__link:hover,.footer__sub__link:focus {
  text-decoration: underline;
  color: #fff;
}

.social-links {
  padding: 0;
  list-style: none;
  width: auto;
  margin: auto;
  text-align: center;
}

.social-links__item {
  display: inline-block;
  vertical-align: middle;
  margin-left: 28px;
}

.social-links__link {
  color: #fff;
  font-size: 2.6em;
  line-height: 1.269230769;
  text-transform: uppercase;
}

.social-links__link:focus,.social-links__link:hover {
  color: #b22a39;
}

.footer__small {
  display: none;
  background: #000;
}

.footer__small__column {
  text-align: center;
}

.footer__small__title {
  margin: 0;
  font-size: 1em;
  line-height: 1;
}

.footer__small__btn {
  color: #ffffff;
  font-family: 'Chunk';
  font-weight: normal;
  font-style: normal;
  font-size: 2.3em;
  background: #000;
  width: 100%;
  display: block;
  border: 1px solid rgba(150, 150, 150, 0.3);
  margin: 0;
  padding: 31px 5px 25px;
}

.footer__small__btn svg {
  fill: #b22a39;
  transition: all 0.25s;
}

.footer__small__btn--open svg {
  fill: #fff;
  transform: rotate(180deg);
}

.footer__small__toggle {
  display: none;
  max-height: 0;
  transition: all 0.25s;
  overflow: hidden;
}

.footer__small__toggle--open {
  max-height: 2000px;
}

.footer__small__toggle--block {
  display: block;
}

.footer__small__list {
  background-color: #1a1a1a;
  padding: 14px 0 9px;
  margin: 0;
  list-style: none;
}

.footer__small__item {
  padding: 7px 0;
}

.footer__small__link {
  opacity: 0.85;
  color: rgba(255, 255, 255, 0.85);
  font-family: Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.7em;
  letter-spacing: 0.22px;
  display: block;
  padding: 10px 5px;
}

.footer__small__arrow {
  position: relative;
  top: -2px;
  left: 2px;
  fill: #b22a39;
  transition: all 0.25s;
}

.footer__small__sub-link {
  color: #fff;
  display: block;
  text-align: center;
  text-transform: uppercase;
  font-family: Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.5em;
  line-height: 1.2;
  letter-spacing: 0.2px;
  padding: 10px;
}

.footer__small__sub-list {
  padding: 27px 0 0;
  margin: 0;
}

.footer__small__sub-item {
  padding-bottom: 10px;
}

#sliding-popup .popup-content #popup-text {
  max-width: 90%;
  margin-top: 13px;
}

#sliding-popup .popup-content #popup-buttons {
  max-width: 10%;
}

#sliding-popup div.popup-content {
  text-align: left;
  padding-top: 5px;
  padding-bottom: 5px;
}

#sliding-popup .popup-content div#popup-text {
  float: none;
  max-width: calc(100% - 65px);
  display: inline-block;
  vertical-align: middle;
}

#sliding-popup .popup-content div#popup-text p {
  text-align: left;
}

#sliding-popup .popup-content div#popup-buttons {
  float: none;
  margin: 0 0 0px 20px;
  display: inline-block;
  vertical-align: middle;
}

#sliding-popup .eu-cookie-compliance-default-button.eu-cookie-compliance-default-button {
  border: none;
  background: none;
  border-radius: 0;
  box-shadow: none;
  transition: all .15s;
}

#sliding-popup .eu-cookie-compliance-default-button.eu-cookie-compliance-default-button:hover,#sliding-popup .eu-cookie-compliance-default-button.eu-cookie-compliance-default-button:focus {
  background: #000;
}

.webform-client-form .form-item {
  margin: 25px 0 10px;
}

.webform-client-form .form-required {
  color: #b22a39;
}

.webform-client-form input:not([type=submit]):not([type=file]),.webform-client-form textarea {
  border-radius: 2px;
  border: 1px solid #adadad;
  background-color: #fff;
  color: #000;
  width: 100%;
  height: 43px;
  font-size: 1.4em;
  font-family: Helvetica, Arial, sans-serif;
  font-style: normal;
}

.webform-client-form input:not([type=submit]):not([type=file])::-webkit-input-placeholder,.webform-client-form textarea::-webkit-input-placeholder {
  font-style: italic;
  color: #9b9b9b;
}

.webform-client-form input:not([type=submit]):not([type=file]):-moz-placeholder,.webform-client-form textarea:-moz-placeholder {
  font-style: italic;
  color: #9b9b9b;
}

.webform-client-form input:not([type=submit]):not([type=file])::-moz-placeholder,.webform-client-form textarea::-moz-placeholder {
  font-style: italic;
  color: #9b9b9b;
}

.webform-client-form input:not([type=submit]):not([type=file]):-ms-input-placeholder,.webform-client-form textarea:-ms-input-placeholder {
  font-style: italic;
  color: #9b9b9b;
}

.webform-client-form textarea {
  height: 150px;
}

.webform-client-form select {
  border-radius: 2px;
  border: 1px solid #adadad;
  background-color: #fff;
  color: #000;
  width: 100%;
  height: 43px;
  padding: 0.625em 1em;
  font-size: 1.4em;
  font-family: Helvetica, Arial, sans-serif;
  font-style: normal;
  max-width: 530px;
}

.webform-client-form .webform-submit,.webform-client-form input[type="submit"] {
  margin-left: 0;
}

.webform-client-form label {
  font-family: 'Chunk';
  font-weight: normal;
  font-style: normal;
  color: #000;
  padding: 4px 0 0;
  font-size: 2.1em;
  text-align: left;
  display: block;
  margin-bottom: 10px;
}

.form-dark .webform-client-form label,.form-dark.webform-client-form label {
  color: #FFF;
}

.view-filters {
  margin-bottom: 40px;
}

.views-exposed-widget label {
  font-weight: bold;
  color: #000;
  padding: 4px 0 0;
  font-size: 1.8em;
  text-align: left;
  display: block;
  margin-bottom: 10px;
}

.views-exposed-widget label + .views-widget {
  display: block;
  margin-bottom: 10px;
}

.views-exposed-widget select {
  font-size: 1.8em;
  display: block;
  margin-bottom: 10px;
}

.views-exposed-widget input[type="submit"] {
  display: inline-block;
  vertical-align: middle;
}

.gallery::after {
  clear: both;
  content: "";
  display: block;
}

.gallery-item {
  box-sizing: border-box;
  display: block;
  float: left;
  text-align: left;
  margin: 0;
  padding: 0 1em 0 0;
}

.gallery-item a {
  display: inline-block;
  max-width: 100%;
}

.gallery-item a img {
  display: block;
  transition: filter 0.2s ease-in;
  backface-visibility: hidden;
}

.gallery-item a img:hover,.gallery-item a img:focus {
  filter: opacity(60%);
}

.gallery-columns-1 .gallery-item {
  width: 100%;
}

.gallery-columns-2 .gallery-item {
  width: 50%;
}

.gallery-columns-3 .gallery-item {
  width: 33.33333%;
}

.gallery-columns-4 .gallery-item {
  width: 25%;
}

.gallery-columns-5 .gallery-item {
  width: 20%;
}

.gallery-columns-6 .gallery-item {
  width: 16.66667%;
}

.gallery-columns-7 .gallery-item {
  width: 14.28571%;
}

.gallery-columns-8 .gallery-item {
  width: 12.5%;
}

.gallery-columns-9 .gallery-item {
  width: 11.11111%;
}

.gallery-caption {
  font-size: .8em;
  padding: 0 0.5em;
}

.listing {
  border-bottom: 1px solid rgba(150, 150, 150, 0.3);
  padding: 15px 0 18px;
}

.listing--image {
  padding: 0;
  border-bottom: none;
  position: relative;
}

.listing--image .listing__title {
  font-family: 'Chunk';
  font-weight: normal;
  font-style: normal;
  font-size: 2.0em;
  line-height: 1.24;
  letter-spacing: 0.02px;
  margin: 0px 0 5px;
}

.listing__image {
  position: relative;
}

.listing__image:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
  z-index: 2;
}

.listing__content {
  position: absolute;
  z-index: 3;
  bottom: 34px;
  left: 20px;
  right: 30px;
}

.listing__title {
  font-family: Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.8em;
  line-height: 1.28;
  color: #FFF;
  margin: 0 0 8px;
}

.listing__title a {
  color: #FFF;
}

.listing__title a:hover,.listing__title a:focus {
  text-decoration: underline;
}

.latest {
  padding: 51px 0 0;
  color: #fff;
}

.latest .wrap,.latest #sliding-popup .popup-content,#sliding-popup .latest .popup-content {
  background: #000;
}

.latest__content {
  margin-bottom: 28px;
}

.latest__title {
  font-family: 'Chunk';
  font-weight: normal;
  font-style: normal;
  font-size: 2.8em;
  line-height: 1.357142857;
  letter-spacing: 0.02px;
  color: #fff;
  padding-right: 5px;
  margin-bottom: 10px;
}

.latest__summary {
  font-family: "Georgia", "Palatino Linotype", "Book Antiqua", "Palatino", "Times New Roman", Times, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.9em;
  color: rgba(255, 255, 255, 0.85);
  line-height: 1.894736842;
  letter-spacing: 0.02px;
  margin: 0 0 33px;
}

.latest__cover img {
  display: block;
  margin: auto;
}

.latest picture {
  display: none;
}

.latest__tri {
  width: 264px;
  margin: 40px auto auto;
  position: relative;
  display: block;
}

.latest__1,.latest__2,.latest__3 {
  display: block;
}

.latest__1 img,.latest__2 img,.latest__3 img {
  display: block;
}

.latest__2,.latest__3 {
  position: absolute;
}

.latest__1 {
  width: 180px;
  margin-top: 15px;
  z-index: 3;
  position: relative;
}

.latest__2 {
  width: 180px;
  transform: rotate(13deg);
  z-index: 2;
  left: 45px;
  top: 0px;
  background-color: #000;
}

.latest__2 img {
  opacity: 0.5;
}

.latest__3 {
  width: 180px;
  transform: rotate(32deg);
  background-color: #000;
  left: 80px;
  top: 0px;
}

.latest__3 img {
  opacity: 0.3;
}

.latest .title-bleed {
  color: #fff;
  font-size: 1.7em;
  margin-bottom: 20px;
}

.magazine {
  padding-bottom: 63px;
  text-align: center;
  position: relative;
}

.magazine__figure {
  display: block;
  margin: auto auto 22px;
  max-width: 80%;
}

.magazine__figure img {
  display: block;
  margin: auto;
}

.past-active .magazine__figure img {
  visibility: hidden;
}

.--past-open .magazine__figure {
  display: none;
}

.magazine__title {
  color: #ffffff;
  font-family: 'Chunk';
  font-weight: normal;
  font-style: normal;
  font-size: 3.1em;
  line-height: 1.225806452;
  letter-spacing: 0.02px;
  padding: 0 20px;
  margin: 11px 0 9px;
}

.magazine__summary {
  color: rgba(255, 255, 255, 0.85);
  font-family: "Georgia", "Palatino Linotype", "Book Antiqua", "Palatino", "Times New Roman", Times, serif;
  font-weight: 400;
  font-style: normal;
}

.magazine__summary p {
  font-size: 2em;
  line-height: 1.6;
  letter-spacing: 0.2px;
  margin: 0 0 1.2em;
}

.magazine__summary p a {
  color: #FFF;
  text-decoration: underline;
  font-weight: bold;
}

.magazine__summary p a:hover,.magazine__summary p a:focus {
  text-decoration: none;
}

.btns + .magazine__summary {
  margin-top: 15px;
}

.past-btn {
  background: transparent;
  border: none;
  color: #fff;
  text-transform: uppercase;
  font-family: Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.4em;
  line-height: 1.285714286;
  margin-bottom: 25px;
}

.past-btn svg {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}

.past-btn__square {
  fill: #b22a39;
  transition: all 0.25s;
}

.past-btn__stroke {
  fill: #fff;
  fill-opacity: 0;
  stroke: #000;
  stroke-miterlimit: 50;
  stroke-width: 2;
}

.past-btn__text {
  display: inline-block;
  vertical-align: middle;
}

.past-btn:hover,.past-btn:focus {
  text-decoration: underline;
}

.past-btn:hover .past-btn__square,.past-btn:focus .past-btn__square {
  fill: #fff;
}

.past {
  list-style: none;
}

.past__link {
  opacity: 0.4;
  display: block;
  color: #fff;
  transition: 0.25s all;
}

.past__link:hover,.past__link:focus {
  text-decoration: underline;
  color: #fff;
  opacity: 1;
}

.past__item.current-past .past__link {
  opacity: 1;
}

.wrap--magazine {
  width: 100%;
  overflow-x: hidden;
}

.magazine-wrap {
  position: relative;
  min-height: 1px;
}

.magazine-wrap.--past-open {
  min-height: 500px;
}

.magazine-latest {
  opacity: 1;
}

.--past-open .magazine-latest {
  opacity: 0;
  pointer-events: none;
}

.magazine-past {
  position: absolute;
  width: calc(100% + 30px);
  top: 7px;
  opacity: 0;
  transition: all 0.25s;
  pointer-events: none;
  min-height: 1px;
}

.--past-open .magazine-past {
  opacity: 1;
  pointer-events: initial;
}

.magazine-past__next,.magazine-past__prev {
  position: absolute;
  width: 45px;
  height: 45px;
  background: none;
  z-index: 2;
  display: none;
  border: none;
  transition: 0.25s all;
  left: 5px;
}

.magazine-past__next svg,.magazine-past__prev svg {
  fill: #fff;
}

.magazine-past__next svg.circle,.magazine-past__prev svg.circle {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.3;
}

.magazine-past__next svg.circle circle,.magazine-past__prev svg.circle circle {
  stroke: #969696;
  fill: transparent;
}

.magazine-past__next svg.circle2,.magazine-past__prev svg.circle2 {
  stroke-dasharray: 135px;
  stroke-dashoffset: 135px;
  transition: stroke-dashoffset 600ms ease;
  position: absolute;
  top: 0;
  left: 0;
}

.magazine-past__next svg.circle2 circle,.magazine-past__prev svg.circle2 circle {
  stroke: #b22a39;
}

.magazine-past__next:hover,.magazine-past__next:focus,.magazine-past__prev:hover,.magazine-past__prev:focus {
  outline: none;
}

.magazine-past__next:hover svg.circle2,.magazine-past__next:focus svg.circle2,.magazine-past__prev:hover svg.circle2,.magazine-past__prev:focus svg.circle2 {
  stroke-dashoffset: 0;
  transform: translate(5, 14);
}

.magazine-past__next svg {
  transform: rotate(-90deg);
}

.magazine-past__prev {
  top: 177px;
}

.magazine-past__prev svg {
  transform: rotate(90deg);
}

.magazine-past__prev svg.circle2 {
  transform: rotate(-90deg);
}

.magazine-past__close {
  background: none;
  border: none;
}

.close-past {
  fill: #b22a39;
}

main {
  position: relative;
  z-index: 2;
  margin-top: 20px;
  overflow-x: hidden;
}

main.--extra-top > *:first-child {
  padding-top: 16px;
}

.meta {
  letter-spacing: .2px;
}

.meta--featured {
  width: 66.3%;
  margin: -15px 0 0 102px;
  padding-bottom: 25px;
}

.meta--article--img {
  margin-left: 20px;
  margin-top: -26px;
  position: absolute;
  z-index: 2;
}

.meta__author {
  font-family: "Georgia", "Palatino Linotype", "Book Antiqua", "Palatino", "Times New Roman", Times, serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1.6em;
}

.meta__author a {
  margin-right: 10px;
}

.meta a {
  text-decoration: none;
  font-family: Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: .9375em;
  color: #FFF;
}

.meta a:hover,.meta a:focus {
  text-decoration: underline;
}

.meta__date {
  color: #414141;
  font-family: Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.4em;
  font-style: normal;
  letter-spacing: 0.2px;
}

.meta__divide {
  content: '';
  height: 21px;
  width: 1px;
  background: #969696;
  opacity: 0.5;
  display: inline-block;
  vertical-align: middle;
  margin: 0 8px;
}

.meta__original {
  display: block;
  margin: 13px 0;
  color: #414141;
  font-family: Helvetica, Arial, sans-serif;
  font-style: italic;
  font-weight: 400;
  font-size: 1.3em;
  letter-spacing: 0.2px;
}

.meta__original a {
  color: #000;
  text-decoration: underline;
}

.meta__original a:hover,.meta__original a:focus {
  text-decoration: none;
}

.meta__original:after {
  content: '';
  height: 1px;
  display: block;
}

.meta__original + .meta__divide {
  display: none;
}

.meta__categories {
  font-family: "Georgia", "Palatino Linotype", "Book Antiqua", "Palatino", "Times New Roman", Times, serif;
  font-weight: 300;
  font-style: italic;
  font-size: 1.6em;
  color: #585f69;
}

.meta__categories a {
  color: #414141;
  font-family: Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: .9375em;
  transition: all .25s;
  display: inline-block;
}

.meta__categories a:after {
  content: ',';
  display: inline-block;
  margin-right: 8px;
}

.meta__categories a:last-child:after {
  display: none;
}

.meta__categories a:hover,.meta__categories a:focus {
  color: #b22a39;
}

.meta__mag {
  font-family: "Freight Sans Pro";
  font-family: Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.5em;
  line-height: 1.733333333;
  text-transform: uppercase;
}

nav.nav.wrap,#sliding-popup nav.nav.popup-content {
  padding-left: 0;
  padding-right: 0;
}

.nav {
  position: relative;
  z-index: 10;
}

.nav__logo {
  background: url(../img/national-interest-logo.png) no-repeat 50% 50%;
  display: block;
  width: 109px;
  height: 53px;
  background-size: 100%;
}

.nav__item:after {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 1px;
  height: 14px;
  background: #b22a39;
}

.nav__list--secondary .nav__item:after {
  background-color: rgba(255, 255, 255, 0.3);
}

.nav__item:last-child:after {
  display: none;
}

.nav__lists {
  text-align: right;
}

.nav__list--main {
  margin-right: 28px;
}

.nav__list--main .nav__link {
  font-family: "Georgia", "Palatino Linotype", "Book Antiqua", "Palatino", "Times New Roman", Times, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 2.2em;
 /* Text style for "Foreign Po" */
  letter-spacing: 0.25px;
}

.nav__list--secondary {
  margin-bottom: 26px;
  margin-right: 11px;
  margin-top: -3px;
}

.nav__list--secondary .nav__link {
  font-family: Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.6em;
  letter-spacing: 0.2px;
}

.nav__link {
  color: #FFF;
  padding: 5px 5px;
  transition: all .25s;
}

.nav__link:hover,.nav__link:focus {
  background: #b22a39;
  color: #FFF;
}

.nav__contain {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  background: #000;
  padding-left: 30px;
  padding-right: 30px;
}

.nav__wrap--desktop {
  display: none;
}

.nav__mag {
  margin-top: -20px;
  position: relative;
}

.nav__mag__link {
  position: relative;
  display: block;
  margin-bottom: -26px;
  transition: all .15s;
  border: 2px solid transparent;
  box-sizing: border-box;
}

.nav__mag__link:hover,.nav__mag__link:focus {
  border-color: #b22a39;
}

.nav__mag__link img {
  display: block;
}

.nav__mag__text {
  background: #b22a39;
  color: #FFF;
  text-transform: uppercase;
  display: block;
  text-align: center;
  padding: 8px 3px;
  font-size: 1.1em;
  font-weight: bold;
  width: 100%;
  box-sizing: border-box;
  transition: all .25s;
  position: absolute;
  bottom: 0px;
}

.nav__mag__subscribe {
  position: absolute;
  width: 100%;
  bottom: -26px;
}

.nav__mag__subscribe:hover .nav__mag__text,.nav__mag__subscribe:focus .nav__mag__text {
  background: #000;
}

.nav-mobile {
  background: #000;
  position: absolute;
  z-index: 2;
  width: 100%;
  left: 0;
  padding: 0 30px 20px;
  box-sizing: border-box;
}

.nav-mobile__button {
  width: 48px;
  height: 48px;
  background: url(../img/hamburger.svg) no-repeat 50% 50%;
  margin: 6px -8px 0 0;
  border: none;
}

.nav-mobile__button--open {
  background-image: url(../img/hamburger-close.svg);
}

.nav-mobile__toggle {
  display: none;
  max-height: 0;
  transition: all .25s;
  overflow: hidden;
}

.nav-mobile__toggle:focus {
  outline: none;
}

.nav-mobile__toggle--block {
  display: block;
}

.nav-mobile__toggle--open {
  max-height: 2000px;
}

.nav-mobile .nav__item {
  border-bottom: 1px solid rgba(150, 150, 150, 0.25);
  padding-bottom: 7px;
  position: relative;
}

.nav-mobile .nav__item:after {
  display: none;
}

.nav-mobile .nav__link {
  display: block;
  width: 90%;
  padding: 23px 2px 11px;
}

.nav-mobile .nav__link:hover,.nav-mobile .nav__link:focus {
  background-color: transparent;
  text-decoration: underline;
}

.nav-mobile .nav__list--main {
  margin: 20px 0 0 0;
}

.nav-mobile .nav__list--secondary {
  margin: 34px 0 20px;
}

.nav-mobile .nav__list--secondary .nav__item {
  border-bottom: none;
  padding-bottom: 9px;
}

.nav-mobile .nav__list--secondary .nav__link {
  padding: 10px 2px;
}

.nav-mobile__subscribe {
  border-radius: 2px;
  background-color: #b22a39;
  color: #FFF;
  display: block;
  text-align: center;
  font-family: Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 1.5em;
  line-height: 1;
  letter-spacing: 1px;
  padding: 16px 6px;
  transition: all .25s;
}

.nav-mobile__subscribe:hover,.nav-mobile__subscribe:focus {
  background: #FFF;
  color: #b22a39;
}

.nav__submenu {
  display: none;
  max-height: 0;
  transition: all .25s;
  overflow: hidden;
}

.nav__submenu--block {
  display: block;
}

.nav__submenu--open {
  max-height: 2000px;
}

.nav__toggle {
  background: url(../img/plus.png) no-repeat 50% 50%;
  width: 48px;
  height: 48px;
  position: absolute;
  border: none;
  right: -10px;
  top: 9px;
  z-index: 2;
}

.nav__toggle--open {
  background-image: url(../img/minus.png);
}

.submenu {
  margin-top: 21px;
}

.submenu__link {
  font-family: Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #b1b1b1;
  font-size: 1.7em;
  padding: 13px 2px;
  display: block;
  transition: all .25s;
}

.submenu__link:hover,.submenu__link:focus {
  color: #FFF;
  text-decoration: underline;
}

.nav-search input[type="text"],.nav-search input[type="search"] {
  color: #ffffff;
  font-family: "Georgia", "Palatino Linotype", "Book Antiqua", "Palatino", "Times New Roman", Times, serif;
  font-weight: 400;
  font-style: italic;
  font-size: 1.5em;
  letter-spacing: 0.2px;
  background: transparent;
  border: none;
  width: 50px;
  transition: all .25s;
}

.nav-search input[type="text"]::-webkit-input-placeholder,.nav-search input[type="search"]::-webkit-input-placeholder {
  color: #ffffff;
}

.nav-search input[type="text"]:-moz-placeholder,.nav-search input[type="search"]:-moz-placeholder {
  color: #ffffff;
}

.nav-search input[type="text"]::-moz-placeholder,.nav-search input[type="search"]::-moz-placeholder {
  color: #ffffff;
}

.nav-search input[type="text"]:-ms-input-placeholder,.nav-search input[type="search"]:-ms-input-placeholder {
  color: #ffffff;
}

.nav-mobile .nav-search input {
  width: calc(100% - 48px);
  padding: 0.625em 1em 0.625em .25em;
}

.nav-search__submit {
  background: url(../img/search.svg) no-repeat 50% 50%;
  background-size: 14px;
  border: none;
  display: inline-block;
  width: 20px;
  height: 16px;
  margin-right: 24px;
}

.nav-mobile .nav-search__submit {
  margin-right: 0;
}

.pagination {
  display: flex;
  justify-content: space-between;
  margin: 0 0 31px;
  position: relative;
}

.pagination__prev,.pagination__next {
  top: 50%;
  transform: translateY(-50%);
  color: #000000;
  font-family: "Georgia", "Palatino Linotype", "Book Antiqua", "Palatino", "Times New Roman", Times, serif;
  font-weight: 300;
  font-style: normal;
  font-size: 1.9em;
  position: absolute;
  min-width: 100px;
}

.pagination__prev[aria-disabled="true"],.pagination__next[aria-disabled="true"] {
  color: #585f69;
  cursor: default;
  pointer-events: none;
}

.pagination__prev[aria-disabled="true"] .pagination__arrow,.pagination__next[aria-disabled="true"] .pagination__arrow {
  fill: #dcdbdc;
}

.pagination__prev {
  left: 0;
}

.pagination__prev .pagination__arrow {
  transform: rotate(90deg);
  top: -2px;
  margin-right: 8px;
}

.pagination__next {
  right: 0;
}

.pagination__next .pagination__arrow {
  transform: rotate(-90deg);
  top: -1px;
  margin-left: 8px;
}

.pagination__list {
  margin: auto;
  padding: 0;
  list-style: none;
  position: relative;
}

.pagination__item {
  display: inline-block;
  position: relative;
}

.pagination__item--active {
  display: inline-block;
}

.pagination__item--active .pagination__link,.pagination__item--active .pagination__current {
  color: 414141;
  border-color: #b22a39;
}

.pagination__link,.pagination__current {
  font-family: 'Chunk';
  font-weight: normal;
  font-style: normal;
  color: #585f69;
  font-size: 2.3em;
  border-bottom: 5px solid transparent;
  min-width: 20px;
  text-align: center;
  padding-bottom: 6px;
  margin: 0 14px;
  display: block;
}

.pagination__arrow {
  fill: #b22a39;
  position: relative;
}

.select2-search--dropdown {
  border-bottom: 1px solid #DDDDDD;
  border-top: 1px solid #DDDDDD;
  padding: 10px;
}

.select2-results__options {
  font-size: 14px;
  font-style: inherit;
  line-height: 1.5;
}

.select2-results__option {
  margin: 0;
  padding: 6px 15px;
}

.share {
  margin: 0;
  padding: 0;
  list-style: none;
  border: 1px solid #f3f1f5;
  display: flex;
  margin-bottom: 39px;
}

.share__item {
  flex-grow: 1;
}

.share__link {
  border: 1px solid #f3f1f5;
  display: block;
  background-color: #ffffff;
  color: #000;
  font-size: 2.5em;
  transition: all .25s;
  text-align: center;
  padding: 11px 5px;
}

.share__link svg {
  fill: #000;
  transition: inherit;
  height: 25px;
}

.share__link:hover,.share__link:focus {
  color: #FFF;
  background-color: #b22a39;
}

.share__link:hover svg,.share__link:focus svg {
  fill: #FFF;
}

.page-node-24327 .share,.page-node-24322 .share {
  display: none;
}

.svg:not(html) {
  display: inline-block;
  line-height: 0;
  vertical-align: middle;
}

.svg:not(html) svg {
  display: block;
  height: 100%;
  width: 100%;
}

.tabs-wrapper {
  max-width: 1200px;
  padding-left: 30px;
  padding-right: 30px;
  margin: 40px auto auto;
  position: relative;
  z-index: 9999;
}

.tabs-wrapper .tabs {
  margin: 25px 0 0;
  padding: 0;
  list-style: none;
}

.tabs-wrapper .tabs li {
  margin: 0 0px 10px;
}

.tabs-wrapper .tabs li:first-child {
  margin-left: 0;
}

.tabs-wrapper .tabs li:last-child {
  margin-right: 0;
}

.tabs-wrapper .tabs li.active a {
  background: #b22a39;
}

.tabs-wrapper .tabs a {
  color: #ffffff;
  font-family: "freight-sans-pro";
  font-style: normal;
  font-weight: 700;
  font-size: 1.3em;
  line-height: 1.615384615;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  padding: 10px 15px;
  display: block;
  border: 2px solid #b22a39;
  transition: all 0.25s;
  background: #000;
}

.tabs-wrapper .tabs a:hover,.tabs-wrapper .tabs a:focus {
  background: #b22a39;
}

#user-login,#user-pass {
  max-width: 1200px;
  padding-left: 30px;
  padding-right: 30px;
  margin: auto auto 30px;
}

#user-login input[type="text"],#user-login input[type="password"],#user-pass input[type="text"],#user-pass input[type="password"] {
  border-radius: 2px;
  border: 1px solid #adadad;
  background-color: #fff;
  color: #000;
  width: 100%;
  height: 43px;
  font-size: 1.4em;
  font-family: Helvetica, Arial, sans-serif;
  font-style: normal;
}

#user-login input[type="text"]::-webkit-input-placeholder,#user-login input[type="password"]::-webkit-input-placeholder,#user-pass input[type="text"]::-webkit-input-placeholder,#user-pass input[type="password"]::-webkit-input-placeholder {
  font-style: italic;
  color: #9b9b9b;
}

#user-login input[type="text"]:-moz-placeholder,#user-login input[type="password"]:-moz-placeholder,#user-pass input[type="text"]:-moz-placeholder,#user-pass input[type="password"]:-moz-placeholder {
  font-style: italic;
  color: #9b9b9b;
}

#user-login input[type="text"]::-moz-placeholder,#user-login input[type="password"]::-moz-placeholder,#user-pass input[type="text"]::-moz-placeholder,#user-pass input[type="password"]::-moz-placeholder {
  font-style: italic;
  color: #9b9b9b;
}

#user-login input[type="text"]:-ms-input-placeholder,#user-login input[type="password"]:-ms-input-placeholder,#user-pass input[type="text"]:-ms-input-placeholder,#user-pass input[type="password"]:-ms-input-placeholder {
  font-style: italic;
  color: #9b9b9b;
}

#user-login input[type="submit"],#user-pass input[type="submit"] {
  margin-left: 0;
}

#user-login label,#user-pass label {
  font-family: 'Chunk';
  font-weight: normal;
  font-style: normal;
  color: #000;
  padding: 4px 0 0;
  margin: 25px 0 10px;
  font-size: 2.1em;
  text-align: left;
  display: block;
}

#user-login .description,#user-pass .description {
  display: block;
  padding: 5px 0;
  font-size: 1.2em;
  margin-bottom: 15px;
  color: #000;
}

.wrap,#sliding-popup .popup-content {
  max-width: 1260px;
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  margin: 0 auto;
  position: relative;
}

.wrap::after,#sliding-popup .popup-content::after {
  clear: both;
  content: "";
  display: block;
}

.wrap--large {
  padding: 0;
}

.wrap.--desktop-bg,#sliding-popup .--desktop-bg.popup-content {
  background-color: #000;
}

.wrap.--desktop-bg + .wrap,#sliding-popup .--desktop-bg.popup-content + .wrap,#sliding-popup .wrap.--desktop-bg + .popup-content,#sliding-popup .--desktop-bg.popup-content + .popup-content {
  background-color: #000;
  padding-top: 10px;
}

.wrap--black-bg {
  background-color: #000;
  padding-top: 10px;
}

.u-cf::after {
  clear: both;
  content: "";
  display: block;
}

.--desktop-bg {
  background-repeat: no-repeat;
  background-position: top left;
  background-size: cover;
  position: relative;
}

.--desktop-bg:after {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  content: '';
  position: absolute;
  width: 100%;
  height: 60%;
  bottom: 0;
  left: 0;
}

.--bg-light {
  background: #FFF;
  padding: 45px 0 0;
}

.--bg-white {
  background: #FFF;
}

.--bg-black {
  background: #000;
}

.-bg-ad-gray {
  background: #edecee;
}

.--ad-right {
  float: right;
  padding: 4px;
  margin: 0 -30px 0 65px;
}

.u-no-children-margin > :first-child {
  margin-top: 0 !important;
}

.u-no-children-margin > :last-child {
  margin-bottom: 0 !important;
}

.featured__image img {
  width: 72px;
  height: 72px;
}

.print {
  display: none;
}

.no-js .lazyload {
  display: none;
}

@font-face {
  font-family:'Chunk';src:url(../fonts/chunk/chunkfive_roman_regular-webfont.eot);src:url(../fonts/chunk/chunkfive_roman_regular-webfont.eot?#iefix) format("embedded-opentype"), url(../fonts/chunk/chunkfive_roman_regular-webfont.woff) format("woff"), url(../fonts/chunk/chunkfive_roman_regular-webfont.ttf) format("truetype"), url(../fonts/chunk/chunkfive_roman_regular-webfont.svg#chunkfiveroman) format("svg");font-weight:normal;font-style:normal;font-display:fallback;
}

@font-face {
  font-family:'Merriweather';font-style:normal;font-weight:300;font-display:fallback;src:url("../fonts/merriweather-v21-latin/merriweather-v21-latin-300.eot");undefined:undefined;src:local("Merriweather Light"), local("Merriweather-Light"), url("../fonts/merriweather-v21-latin/merriweather-v21-latin-300.eot?#iefix") format("embedded-opentype"), url("../fonts/merriweather-v21-latin/merriweather-v21-latin-300.woff2") format("woff2"), url("../fonts/merriweather-v21-latin/merriweather-v21-latin-300.woff") format("woff"), url("../fonts/merriweather-v21-latin/merriweather-v21-latin-300.ttf") format("truetype"), url("../fonts/merriweather-v21-latin/merriweather-v21-latin-300.svg#Merriweather") format("svg");undefined:undefined;
}

@font-face {
  font-family:'Merriweather';font-style:italic;font-weight:300;font-display:fallback;src:url("../fonts/merriweather-v21-latin/merriweather-v21-latin-300italic.eot");undefined:undefined;src:local("Merriweather Light Italic"), local("Merriweather-LightItalic"), url("../fonts/merriweather-v21-latin/merriweather-v21-latin-300italic.eot?#iefix") format("embedded-opentype"), url("../fonts/merriweather-v21-latin/merriweather-v21-latin-300italic.woff2") format("woff2"), url("../fonts/merriweather-v21-latin/merriweather-v21-latin-300italic.woff") format("woff"), url("../fonts/merriweather-v21-latin/merriweather-v21-latin-300italic.ttf") format("truetype"), url("../fonts/merriweather-v21-latin/merriweather-v21-latin-300italic.svg#Merriweather") format("svg");undefined:undefined;
}

@font-face {
  font-family:'Merriweather';font-style:normal;font-weight:400;font-display:fallback;src:url("../fonts/merriweather-v21-latin/merriweather-v21-latin-regular.eot");undefined:undefined;src:local("Merriweather Regular"), local("Merriweather-Regular"), url("../fonts/merriweather-v21-latin/merriweather-v21-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/merriweather-v21-latin/merriweather-v21-latin-regular.woff2") format("woff2"), url("../fonts/merriweather-v21-latin/merriweather-v21-latin-regular.woff") format("woff"), url("../fonts/merriweather-v21-latin/merriweather-v21-latin-regular.ttf") format("truetype"), url("../fonts/merriweather-v21-latin/merriweather-v21-latin-regular.svg#Merriweather") format("svg");undefined:undefined;
}

@font-face {
  font-family:'Merriweather';font-style:italic;font-weight:400;font-display:fallback;src:url("../fonts/merriweather-v21-latin/merriweather-v21-latin-italic.eot");undefined:undefined;src:local("Merriweather Italic"), local("Merriweather-Italic"), url("../fonts/merriweather-v21-latin/merriweather-v21-latin-italic.eot?#iefix") format("embedded-opentype"), url("../fonts/merriweather-v21-latin/merriweather-v21-latin-italic.woff2") format("woff2"), url("../fonts/merriweather-v21-latin/merriweather-v21-latin-italic.woff") format("woff"), url("../fonts/merriweather-v21-latin/merriweather-v21-latin-italic.ttf") format("truetype"), url("../fonts/merriweather-v21-latin/merriweather-v21-latin-italic.svg#Merriweather") format("svg");undefined:undefined;
}

@font-face {
  font-family:'Merriweather';font-style:normal;font-weight:700;font-display:fallback;src:url("../fonts/merriweather-v21-latin/merriweather-v21-latin-700.eot");undefined:undefined;src:local("Merriweather Bold"), local("Merriweather-Bold"), url("../fonts/merriweather-v21-latin/merriweather-v21-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/merriweather-v21-latin/merriweather-v21-latin-700.woff2") format("woff2"), url("../fonts/merriweather-v21-latin/merriweather-v21-latin-700.woff") format("woff"), url("../fonts/merriweather-v21-latin/merriweather-v21-latin-700.ttf") format("truetype"), url("../fonts/merriweather-v21-latin/merriweather-v21-latin-700.svg#Merriweather") format("svg");undefined:undefined;
}

@font-face {
  font-family:'Merriweather';font-style:italic;font-weight:700;font-display:fallback;src:url("../fonts/merriweather-v21-latin/merriweather-v21-latin-700italic.eot");undefined:undefined;src:local("Merriweather Bold Italic"), local("Merriweather-BoldItalic"), url("../fonts/merriweather-v21-latin/merriweather-v21-latin-700italic.eot?#iefix") format("embedded-opentype"), url("../fonts/merriweather-v21-latin/merriweather-v21-latin-700italic.woff2") format("woff2"), url("../fonts/merriweather-v21-latin/merriweather-v21-latin-700italic.woff") format("woff"), url("../fonts/merriweather-v21-latin/merriweather-v21-latin-700italic.ttf") format("truetype"), url("../fonts/merriweather-v21-latin/merriweather-v21-latin-700italic.svg#Merriweather") format("svg");undefined:undefined;
}

@font-face {
  font-family:'Merriweather';font-style:normal;font-weight:900;font-display:fallback;src:url("../fonts/merriweather-v21-latin/merriweather-v21-latin-900.eot");undefined:undefined;src:local("Merriweather Black"), local("Merriweather-Black"), url("../fonts/merriweather-v21-latin/merriweather-v21-latin-900.eot?#iefix") format("embedded-opentype"), url("../fonts/merriweather-v21-latin/merriweather-v21-latin-900.woff2") format("woff2"), url("../fonts/merriweather-v21-latin/merriweather-v21-latin-900.woff") format("woff"), url("../fonts/merriweather-v21-latin/merriweather-v21-latin-900.ttf") format("truetype"), url("../fonts/merriweather-v21-latin/merriweather-v21-latin-900.svg#Merriweather") format("svg");undefined:undefined;
}

@font-face {
  font-family:'Merriweather';font-style:italic;font-weight:900;font-display:fallback;src:url("../fonts/merriweather-v21-latin/merriweather-v21-latin-900italic.eot");undefined:undefined;src:local("Merriweather Black Italic"), local("Merriweather-BlackItalic"), url("../fonts/merriweather-v21-latin/merriweather-v21-latin-900italic.eot?#iefix") format("embedded-opentype"), url("../fonts/merriweather-v21-latin/merriweather-v21-latin-900italic.woff2") format("woff2"), url("../fonts/merriweather-v21-latin/merriweather-v21-latin-900italic.woff") format("woff"), url("../fonts/merriweather-v21-latin/merriweather-v21-latin-900italic.ttf") format("truetype"), url("../fonts/merriweather-v21-latin/merriweather-v21-latin-900italic.svg#Merriweather") format("svg");undefined:undefined;
}

@font-face {
  font-family:'Source Sans Pro';font-style:normal;font-weight:400;font-display:fallback;src:url("../fonts/source-sans-pro-v13-latin/source-sans-pro-v13-latin-regular.eot");undefined:undefined;src:local("Source Sans Pro Regular"), local("SourceSansPro-Regular"), url("../fonts/source-sans-pro-v13-latin/source-sans-pro-v13-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/source-sans-pro-v13-latin/source-sans-pro-v13-latin-regular.woff2") format("woff2"), url("../fonts/source-sans-pro-v13-latin/source-sans-pro-v13-latin-regular.woff") format("woff"), url("../fonts/source-sans-pro-v13-latin/source-sans-pro-v13-latin-regular.ttf") format("truetype"), url("../fonts/source-sans-pro-v13-latin/source-sans-pro-v13-latin-regular.svg#SourceSansPro") format("svg");undefined:undefined;
}

@font-face {
  font-family:'Source Sans Pro';font-style:italic;font-weight:400;font-display:fallback;src:url("../fonts/source-sans-pro-v13-latin/source-sans-pro-v13-latin-italic.eot");undefined:undefined;src:local("Source Sans Pro Italic"), local("SourceSansPro-Italic"), url("../fonts/source-sans-pro-v13-latin/source-sans-pro-v13-latin-italic.eot?#iefix") format("embedded-opentype"), url("../fonts/source-sans-pro-v13-latin/source-sans-pro-v13-latin-italic.woff2") format("woff2"), url("../fonts/source-sans-pro-v13-latin/source-sans-pro-v13-latin-italic.woff") format("woff"), url("../fonts/source-sans-pro-v13-latin/source-sans-pro-v13-latin-italic.ttf") format("truetype"), url("../fonts/source-sans-pro-v13-latin/source-sans-pro-v13-latin-italic.svg#SourceSansPro") format("svg");undefined:undefined;
}

@font-face {
  font-family:'Source Sans Pro';font-style:normal;font-weight:600;font-display:fallback;src:url("../fonts/source-sans-pro-v13-latin/source-sans-pro-v13-latin-600.eot");undefined:undefined;src:local("Source Sans Pro SemiBold"), local("SourceSansPro-SemiBold"), url("../fonts/source-sans-pro-v13-latin/source-sans-pro-v13-latin-600.eot?#iefix") format("embedded-opentype"), url("../fonts/source-sans-pro-v13-latin/source-sans-pro-v13-latin-600.woff2") format("woff2"), url("../fonts/source-sans-pro-v13-latin/source-sans-pro-v13-latin-600.woff") format("woff"), url("../fonts/source-sans-pro-v13-latin/source-sans-pro-v13-latin-600.ttf") format("truetype"), url("../fonts/source-sans-pro-v13-latin/source-sans-pro-v13-latin-600.svg#SourceSansPro") format("svg");undefined:undefined;
}

@font-face {
  font-family:'Source Sans Pro';font-style:normal;font-weight:700;font-display:fallback;src:url("../fonts/source-sans-pro-v13-latin/source-sans-pro-v13-latin-700.eot");undefined:undefined;src:local("Source Sans Pro Bold"), local("SourceSansPro-Bold"), url("../fonts/source-sans-pro-v13-latin/source-sans-pro-v13-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/source-sans-pro-v13-latin/source-sans-pro-v13-latin-700.woff2") format("woff2"), url("../fonts/source-sans-pro-v13-latin/source-sans-pro-v13-latin-700.woff") format("woff"), url("../fonts/source-sans-pro-v13-latin/source-sans-pro-v13-latin-700.ttf") format("truetype"), url("../fonts/source-sans-pro-v13-latin/source-sans-pro-v13-latin-700.svg#SourceSansPro") format("svg");undefined:undefined;
}

@font-face {
  font-family:"Socialico";src:url("../fonts/Socialico/Socialico.eot?") format("eot"), url("../fonts/Socialico/Socialico.woff") format("woff"), url("../fonts/Socialico/Socialico.ttf") format("truetype"), url("../fonts/Socialico/Socialico.svg#Socialico") format("svg");font-weight:normal;font-style:normal;font-display:fallback;
}

@media screen and (min-width: 600px) {
  .mobile--only {
    display: none;
  }
}

@media (min-width: 600px) and (max-width: 999px) {
  .featured-articles__wrap {
    display: flex;
    flex-wrap: wrap;
    align-content: stretch;
    justify-content: space-between;
  }

  .featured-articles__wrap .featured {
    width: 48%;
  }

  .popular .featured-articles__wrap {
    display: block;
  }

  .popular .featured-articles__wrap .featured__image {
    width: 70px;
  }

  .popular .featured-articles__wrap .featured {
    width: 100%;
    max-width: 660px;
  }
}

@media (min-width: 640px) {
  .hp-featured__content {
    margin-top: -25%;
  }

  .article-grid__secondary {
    display: flex;
    justify-content: space-between;
  }

  .article-grid__secondary .article {
    margin-right: 15px;
  }

  .article-grid__secondary .article:last-child {
    margin-right: 0;
  }

  .blog-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .blogs {
    width: 275px;
  }

  .blogs:nth-child(odd) {
    margin-right: 2.916666667%;
  }

  .blogs:last-child {
    margin-right: 0;
  }

  .bx-wrap {
    max-width: 100%;
  }

  .flex {
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
  }

  .flex--2 > * {
    width: 48.095238095%;
  }
}

@media (min-width: 768px) {
  .article--flex {
    display: flex;
    justify-content: space-between;
  }

  .article--flex .article__img {
    max-width: 275px;
    margin-right: 35px;
  }

  .article--cta {
    justify-content: flex-start;
    align-items: center;
  }

  .author {
    display: flex;
    justify-content: space-between;
  }

  .author__image {
    width: 164px;
    margin: 15px 20px 0 0;
  }

  .author__content {
    text-align: left;
    max-width: 558px;
    width: 100%;
  }

  .author__content p {
    margin: 0 0 7px;
  }

  .blogs {
    margin-right: 2.916666667%;
  }

  .blogs:last-child {
    margin-right: 0;
  }

  .category {
    text-align: left;
  }

  .category__title {
    font-size: 5em;
    line-height: 1.16;
  }

  .category__title:after {
    margin: 5px 0 -15px 0;
  }

  .detail__title--large {
    display: block;
  }

  .detail__title--small {
    display: none;
  }

  .featured-articles__title--small {
    font-size: 1.6em;
  }

  .views-exposed-widget {
    display: inline-block;
    vertical-align: middle;
  }

  .views-exposed-widget label {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0;
  }

  .views-exposed-widget label + .views-widget {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
    margin-bottom: 0;
  }

  .views-exposed-widget select {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    margin-bottom: 0;
  }

  .latest__cover {
    position: relative;
  }

  .magazine {
    display: flex;
    text-align: left;
    align-items: center;
    padding-bottom: 40px;
  }

  .magazine__figure {
    width: 43.083333%;
    margin-bottom: -3.4em;
    margin-left: 45px;
    margin-right: 4%;
  }

  .magazine__content {
    width: 65.916666667%;
  }

  .magazine__title {
    padding: 0;
  }

  .past-btn {
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    left: -16px;
    width: 60px;
  }

  .past-btn--close {
    bottom: auto;
    top: 50%;
    transform: translateY(-50%);
    left: auto;
  }

  .magazine-past__btns {
    position: absolute;
    top: 72px;
    height: 221px;
    left: -16px;
  }

  .magazine-past__next,.magazine-past__prev {
    display: block;
  }

  .magazine-past .bx-wrapper {
    margin-left: 50px;
  }

  .pagination__list {
    text-align: center;
    width: 100%;
  }

  .pagination__item {
    top: 7px;
  }

  .tabs-wrapper .tabs li {
    display: inline-block;
    margin: 0 5px 10px;
  }
}

@media (min-width: 800px) {
  .--ad-left {
    float: left;
    margin: 25px 65px 0px 0;
  }

  .article--img .article__title,.hp-featured--img .article__title {
    bottom: 25px;
    padding-right: 10px;
  }

  .article-grid {
    margin-bottom: 38px;
  }

  .article-grid__secondary {
    font-size: 1em;
  }

  .blogs__title {
    font-size: 2.1em;
    margin-bottom: 32px;
  }

  .post-pagination.--bg-light {
    padding: 0;
  }

  .footer .wrap,.footer #sliding-popup .popup-content,#sliding-popup .footer .popup-content {
    padding: 46px 30px 0;
  }

  .footer__mag {
    width: 23.4166667%;
    margin-bottom: -4.5em;
  }

  .footer__sub .wrap,.footer__sub #sliding-popup .popup-content,#sliding-popup .footer__sub .popup-content {
    padding: 0 30px;
  }

  .meta__author {
    font-size: 1.7em;
  }

  .article--flex .meta__author {
    font-size: 1.8em;
  }

  .meta__date {
    font-size: 1.7em;
  }

  .--bg-light {
    padding: 45px 0 60px;
  }

  .--bg-light.--extra-top {
    padding-top: 98px;
  }

  .--small-only {
    display: none;
  }
}

@media (max-width: 999px) and (min-width: 600px) {
  .details__related {
    display: block;
  }
}

@media (min-width: 1000px) {
  .ad--728 {
    display: inline-block;
    max-width: 1260px;
    margin: auto;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  .ad--162 {
    display: inline-block;
  }

  .main-featured {
    display: flex;
    position: relative;
    z-index: 2;
  }

  .main-featured:before,.main-featured:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    max-width: 445px;
  }

  .article__title,.hp-featured__title {
    font-size: 3em;
    line-height: 1.204545455;
    margin: 15px 0 4px;
  }

  .article__summary,.hp-featured__summary {
    font-size: 2.3em;
    line-height: 1.47826087;
    margin: 0 0 15px;
  }

  .term-title {
    font-size: 3.2em;
    line-height: 1.204545455;
    margin: 15px 0 4px;
  }

  .hp-featured {
    width: calc(100% - 494px);
  }

  .hp-featured picture {
    visibility: hidden;
  }

  .hp-featured__content {
    margin-top: -17%;
    max-width: 744px;
    float: right;
  }

  .hp-featured__category {
    font-size: 1.6em;
    margin-bottom: 15px;
  }

  .article--grid {
    margin-bottom: 45px;
  }

  .article--grid .article__title {
    font-size: 3em;
    line-height: 1.233333333;
    margin: 26px 0 9px;
  }

  .article--grid .article__summary {
    font-size: 1.9em;
    line-height: 1.631578947;
  }

  .article--grid .meta__author {
    font-size: 1.8em;
  }

  .article--flex .article__title {
    margin: 36px 0 14px;
  }

  .article--flex .article__content {
    width: calc(100% - 310px);
  }

  .article--cta .article__title {
    margin-top: 0;
    font-size: 4.2em;
  }

  .article-grid {
    display: flex;
    justify-content: space-between;
  }

  .article-grid__main {
    margin-right: 15px;
    margin-bottom: 15px;
  }

  .flex-l .article-grid__main {
    margin-right: 0;
  }

  .article-grid__main .article__title {
    font-size: 4.4em;
    line-height: 1.204545455;
    left: 30px;
    right: 65px;
    bottom: 25px;
  }

  .article-grid__secondary {
    display: block;
    font-size: 0.9em;
  }

  .article-grid__secondary .article {
    margin-right: 0;
  }

  .archives__wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .archives .listing--image {
    max-width: calc(25% - (2.916666667% / 1.5));
    flex-grow: 1;
  }

  .archives .listing--image:nth-child(4n + 4) {
    margin-right: 0;
  }

  .author__image {
    margin: 15px 65px 0 0;
  }

  .blogs {
    margin-right: 1%;
  }

  .blogs:nth-child(odd) {
    margin-right: 1%;
  }

  .blogs:last-child {
    margin-right: 0;
  }

  .blogs__all {
    position: relative;
    top: 12px;
  }

  .bx-wrap {
    max-width: 10000px;
  }

  .blog-list-bx {
    padding-bottom: 60px;
  }

  .article-detail {
    padding: 55px 0;
  }

  .detail__title {
    font-size: 5.6em;
    margin: 23px 0px 19px;
  }

  .detail__sub {
    font-size: 2.3em;
    padding: 16px 0;
    margin: 0 0 20px;
  }

  .detail__content + .pagination {
    margin: 44px 0 33px;
  }

  .detail__content p {
    font-size: 2em;
    line-height: 1.7;
    margin-bottom: 2em;
  }

  .pullquote p {
    font-size: 2.4em;
  }

  .pullquote--left {
    width: 340px;
    margin: 25px 65px 25px 0;
    float: left;
  }

  .pullquote--right {
    width: 340px;
    margin: 25px 0 25px 65px;
    float: right;
  }

  .pullquote--full {
    margin: 72px 0 51px 0;
  }

  .pullquote--full p {
    font-size: 3.5em;
    line-height: 1.5;
    margin: .95em 0 0.6em;
  }

  .pullquote--full p cite {
    font-size: .6em;
  }

  .details__related__title {
    margin: 0 0 19px;
  }

  .details__related .featured__title {
    width: 70.23%;
  }

  .details__related .featured__image {
    width: 27.15%;
    margin-right: 13px;
  }

  .details__related .featured__image img {
    height: auto;
    width: auto;
  }

  .details__related .featured__link {
    padding: 10px 0;
  }

  .recommended {
    margin: 38px 0 40px;
  }

  .recommended p {
    font-size: 2.4em;
    margin-bottom: 0.6em;
  }

  .recommended p a {
    display: inline;
  }

  .meta--detail {
    margin-bottom: 45px;
  }

  .meta--detail .meta__date {
    font-size: 1.9em;
  }

  .featured-articles {
    margin-top: 0;
  }

  .featured-articles__title {
    font-size: 2.1em;
    max-width: 328px;
    margin-bottom: 4px;
  }

  .featured-articles__title--small {
    font-size: 1.6em;
    text-align: left;
    margin: -6px 0 -2px 10px;
  }

  .featured-articles {
    background-color: rgba(0, 0, 0, 0.7);
    padding: 16px 30px 0;
    margin-right: 0;
  }

  .main-featured .featured-articles {
    padding-top: 60px;
  }

  .featured__link {
    padding: 25px 0;
  }

  .featured__title {
    font-size: 1.6em;
  }

  .featured__image {
    margin-right: 30px;
  }

  .featured__all {
    width: auto;
  }

  .recently-popular {
    padding: 64px 0 52px;
  }

  .popular .ad {
    margin: auto -8px 30px;
  }

  .title-bleed {
    font-size: 2em;
    margin-bottom: 29px;
  }

  .flex-l {
    flex-direction: row;
  }

  .flex-l__2-3 {
    max-width: 840px;
    width: 100%;
  }

  .flex-l__2-3 + .flex-l__1-3 {
    padding-left: 30px;
  }

  .flex-l__2-3.--narrow {
    max-width: 789px;
  }

  .flex-l__1-3 {
    max-width: 329px;
  }

  .footer__flex {
    flex-direction: row;
  }

  .footer__links {
    width: 73.75%;
  }

  .newsletter {
    display: flex;
    justify-content: space-between;
  }

  .newsletter__form {
    padding-top: 0;
  }

  .newsletter__flex {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .newsletter__legend {
    width: 100%;
    text-align: left;
    margin-bottom: 0;
    flex-grow: 2;
    line-height: 1;
    position: relative;
    top: -3px;
    margin-bottom: 3px;
  }

  .newsletter__inputs {
    width: 100%;
    text-align: right;
    display: flex;
    position: relative;
    top: -4px;
  }

  .newsletter__input {
    height: 25px;
    max-width: 385px;
  }

  .footer__sub p {
    font-size: 1.3em;
    line-height: 2.153846154;
    padding-left: 3.833333%;
    text-align: left;
  }

  .footer__sub__links {
    margin: auto auto auto 40px;
    text-align: left;
  }

  .social-links {
    margin: 0;
    text-align: left;
  }

  .webform-client-form input:not([type=submit]):not([type=file]),.webform-client-form textarea {
    height: 25px;
    max-width: 500px;
  }

  .webform-client-form textarea {
    height: 150px;
  }

  .listing {
    padding: 21px 0;
  }

  .listing--image {
    margin-bottom: 8px;
    padding: 0;
  }

  .listing__content {
    bottom: 28px;
  }

  .latest {
    padding: 51px 0;
  }

  .latest__content {
    width: 60.544218%;
    padding-top: 58px;
  }

  .latest__title {
    font-size: 4em;
    line-height: 1.45;
    margin-top: 0;
  }

  .latest__summary {
    font-size: 2.2em;
    line-height: 1.681818182;
    margin: 0 0 27px;
  }

  .latest__cover {
    width: 34.707482993%;
    margin-bottom: auto;
  }

  .latest__cover img {
    margin: 0;
  }

  .latest__tri {
    width: 245px;
  }

  .latest__1 {
    width: 245px;
  }

  .latest__2 {
    width: 208px;
    left: 73px;
    top: 31px;
  }

  .latest__3 {
    width: 176px;
    left: 140px;
    top: 70px;
  }

  .latest .title-bleed {
    font-size: 2em;
  }

  .latest__wrap {
    display: flex;
    justify-content: space-between;
  }

  .magazine {
    padding-bottom: 0;
    margin-top: -5px;
  }

  .magazine__figure {
    width: 27.083333333%;
    margin-left: 60px;
    margin-right: 2.5%;
  }

  .magazine__summary p {
    margin: 0 0 1.5em;
  }

  .past-btn {
    bottom: auto;
    margin-top: -31px;
    left: -10px;
  }

  .past-btn--close {
    left: -5px;
    margin-top: 0;
  }

  .past-btn svg {
    margin: auto auto 9px;
  }

  .bx-wrapper .meta__mag {
    font-size: 1.4em;
    text-align: center;
    display: block;
    margin-top: 17px;
  }

  .magazine-past {
    min-height: 500px;
  }

  .magazine-past__btns {
    left: -6px;
  }

  main.--extra-top > *:first-child {
    padding-top: 59px;
  }

  .article-grid__main .meta--article--img {
    margin-left: 32px;
    margin-top: -26px;
  }

  .meta__original {
    font-size: 1.2em;
    display: inline-block;
    margin: 11px 9px 11px 9px;
  }

  .meta__original + .meta__divide {
    display: inline-block;
  }

  .meta__categories {
    font-size: 1.9em;
  }

  .meta__mag {
    font-size: 1.6em;
  }

  nav.nav {
    margin: 0 auto -20px;
  }

  .nav__logo {
    width: 160px;
    height: 76px;
  }

  .nav__item {
    display: inline-block;
    margin-left: 5px;
    position: relative;
    z-index: 2;
  }

  .nav__item:hover .nav__submenu,.nav__item:focus .nav__submenu {
    display: block;
    opacity: 1;
  }

  .nav__list--main .nav__item {
    padding-bottom: 21px;
  }

  .nav__list--main .nav__item:hover > .nav__link {
    color: rgba(255, 255, 255, 0.67);
  }

  .nav__list--main .nav__item:hover > .nav__link:hover,.nav__list--main .nav__item:hover > .nav__link:focus {
    color: #FFF;
  }

  .nav__list--main .nav__link {
    font-size: 1.7em;
  }

  .nav__list--secondary .nav__link {
    font-size: 1.5em;
  }

  .nav__wrap--desktop {
    display: flex;
    justify-content: flex-end;
    width: calc(100% - 160px);
  }

  .nav-mobile {
    display: none;
  }

  .nav-mobile__button {
    display: none;
  }

  .nav__submenu {
    opacity: 0;
    transition: 0.25s all;
    position: absolute;
    max-height: 1000px;
    min-width: 160px;
    background-color: #1f1f1f;
    text-align: left;
    left: 50%;
    transform: translateX(-50%);
    overflow: visible;
    margin-top: 21px;
  }

  .nav__submenu:before {
    content: '';
    display: block;
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 7.5px 10px 7.5px;
    border-color: transparent transparent #1f1f1f transparent;
  }

  .submenu {
    margin-top: 0;
    padding: 21px 0;
  }

  .submenu__link {
    padding: 13px 19px;
    color: #FFF;
    font-size: 1.6em;
  }

  .submenu__link:hover,.submenu__link:focus {
    color: #FFF;
    text-decoration: none;
    background-color: #b22a39;
  }

  .nav-search input[type="text"]:focus,.nav-search input[type="text"].not-empty,.nav-search input[type="search"]:focus,.nav-search input[type="search"].not-empty {
    width: 140px;
  }

  .pagination {
    margin: 84px 0 67px;
  }

  .share__link svg {
    height: 31px;
  }

  .share__link {
    font-size: 3.1em;
    padding: 23px 5px 18px;
  }

  #user-login input[type="text"],#user-login input[type="password"],#user-pass input[type="text"],#user-pass input[type="password"] {
    height: 25px;
    max-width: 385px;
  }

  .--margin-title-bleed {
    margin-top: 53px;
  }
}

@media (min-width: 1000px) and (max-width: 1125px) {
  .newsletter__legend {
    font-size: 1.8em;
  }
}

@media (min-width: 1000px) and (max-width: 1099px) {
  .footer__sub__contain {
    padding: 13px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media (min-width: 1100px) {
  .footer__sub__contain {
    padding: 13px 0 13px 23.4166667%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .nav__list--main .nav__link {
    font-size: 1.8em;
  }
}

@media (min-width: 1175px) {
  .nav__list--main .nav__link {
    padding: 5px 11px;
    font-size: 1.8em;
  }
}

@media (min-width: 1200px) {
  .--ad-left {
    margin-left: -101px;
  }

  .blog-list {
    flex-wrap: nowrap;
  }

  .detail {
    min-height: 375px;
  }

  .pullquote--left {
    margin-left: -101px;
  }

  .pullquote--right {
    margin-right: -101px;
  }

  .pullquote--full {
    margin-left: -101px;
    width: calc(100% + 215px);
  }

  .details__related--left {
    width: 340px;
    margin: 25px 65px -1px -101px;
    float: left;
  }

  .magazine-past .bx-wrapper {
    margin-left: 70px;
  }

  .nav__link {
    padding: 5px 10px;
  }

  .share {
    display: block;
    width: 80px;
    position: absolute;
    left: -150px;
  }
}

@media (min-width: 1300px) {
  .latest__1,.latest__2,.latest__3 {
    margin: 0 0 0 41px;
  }
}

@media (min-width: 1400px) {
  .hp-featured__content {
    margin-right: 7%;
  }

  .past-btn {
    width: 60px;
    bottom: auto;
  }

  .past-btn--close {
    left: -5px;
    margin-top: 0;
  }
}

@media (max-width: 1199px) {
  .ad--162.--ad-right {
    margin-right: auto;
  }

  .featured__all--border {
    margin-bottom: 20px;
  }

  .footer__sub__item:first-child:before {
    display: none;
  }

  .social-links__item:first-child {
    margin-left: 0;
  }
}

@media screen and (max-width: 1024px) {
  .touchevents .ad--hide-tablet-down {
    display: none;
  }
}

@media (max-width: 999px) {
  .footer__large {
    display: none;
  }

  .footer__mag {
    margin: auto;
    margin-bottom: -15em;
    max-width: 233px;
  }

  .newsletter {
    margin-left: -30px;
    margin-right: -30px;
  }

  .newsletter__cfni img {
    margin: auto;
  }

  .newsletter__form {
    padding-left: 30px;
    padding-right: 30px;
  }

  .newsletter__input {
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 10px;
  }

  .newsletter .btn--primary,.newsletter .webform-client-form .webform-submit,.webform-client-form .newsletter .webform-submit,.newsletter .webform-client-form input[type="submit"],.webform-client-form .newsletter input[type="submit"],.newsletter .views-exposed-widget input[type="submit"],.views-exposed-widget .newsletter input[type="submit"],.newsletter #user-login input[type="submit"],#user-login .newsletter input[type="submit"],.newsletter #user-pass input[type="submit"],#user-pass .newsletter input[type="submit"] {
    width: 100%;
    margin: 0 0 50px;
  }

  .footer__sub .wrap,.footer__sub #sliding-popup .popup-content,#sliding-popup .footer__sub .popup-content {
    padding-top: 165px;
  }

  .footer__small {
    display: block;
  }

  .webform-client-form input:not([type=submit]):not([type=file]),.webform-client-form textarea {
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 10px;
  }

  .latest .wrap,.latest #sliding-popup .popup-content,#sliding-popup .latest .popup-content {
    padding-top: 20px;
    padding-bottom: 30px;
  }

  .past__link {
    text-align: center;
  }

  .past__link img {
    margin-bottom: 20px;
  }

  #user-login input[type="text"],#user-login input[type="password"],#user-pass input[type="text"],#user-pass input[type="password"] {
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 10px;
  }

  .--desktop-bg {
    background-color: #000 !important;
    background-size: cover !important;
  }

  .--desktop-bg:after {
    display: none;
  }

  .--large-only {
    display: none;
  }
}

@media (max-width: 767px) {
  .ad--162.--ad-right {
    float: none;
    margin-left: auto;
    margin-right: auto;
    padding: 4px;
    display: none;
  }

  .ad--center {
    margin-left: auto;
    margin-right: auto;
  }

  .ad--center > div:first-child {
    padding: 4px;
    min-width: 320px;
  }

  .ad--hide-mobile {
    display: none;
  }

  .blogs {
    margin-bottom: 50px;
  }

  .meta--detail .meta__row {
    margin-bottom: 20px;
  }

  .magazine-past__btns {
    width: 50%;
    text-align: center;
    margin: -5px 0;
  }

  .magazine-past .bx-wrapper {
    margin: 5px 0 60px 31px;
  }

  .pagination__pager {
    position: absolute;
    top: 5px;
    width: 45%;
  }

  .pagination__pager.first {
    left: 0;
  }

  .pagination__pager.last {
    right: 0;
  }

  .pagination__item {
    margin-bottom: 40px;
    top: 45px;
  }
}

@media (max-width: 600) {
  .hp-featured__content {
    margin-top: -50%;
    padding-top: 4.5%;
  }
}

@media print {
  .print {
    display: block;
  }

  .nav.wrap,#sliding-popup .nav.popup-content,.ad,.footer__small,.footer__flex,.footer__sub__links,.social-links,.--desktop-bg:after,.main-featured:before,.main-featured:after,.hp-featured img,.bx-pager,.btns,.archives,.article--img .article__link:after,.hp-featured--img .article__link:after,.article--img img,.listing__image,.blogs__all,.bx-clone,.share,.article--cta img,.featured__all,button {
    display: none !important;
  }

  .pagination__list > li {
    display: none;
  }

  .pagination__list > .pagination__item--active {
    display: block;
  }

  .hp-featured__content {
    margin-top: 0;
  }

  .footer,.footer__sub {
    padding: 0;
  }

  body {
    background: #FFF;
  }

  * {
    background: none !important;
    color: #000 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .bx-wrap,.blogs,.article--flex .article__content {
    max-width: 100% !important;
    width: 100% !important;
  }

  .article--img .article__title,.hp-featured--img .article__title,.listing__content {
    position: relative;
    left: auto;
    right: auto;
    top: auto;
    bottom: auto;
  }

  .article__img img {
    max-height: 250px;
    max-width: 100% !important;
    width: auto !important;
  }

  .featured {
    max-width: 100%;
  }

  .footer__sub p {
    text-align: left;
  }

  .footer__sub__contain {
    padding: 13px 0;
  }
}
