@charset "UTF-8";
@mixin placeholder() {
    &::-webkit-input-placeholder {
        @content;
    }
    &:-moz-placeholder {
        @content;
    }
    &::-moz-placeholder {
        @content;
    }
    &:-ms-input-placeholder {
        @content;
    }
}

@mixin smooth(){
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}