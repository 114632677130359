.pagination {
	display: flex;
	justify-content: space-between;
	margin: 0 0 31px;
	position: relative;
	@media #{$large-up} {
		margin: 84px 0 67px;
	}
	&__prev,
	&__next {
		top: 50%;
		transform: translateY(-50%);
		color: #000000;
		@include ft-pro-300;
		font-size: 1.9em;
		position: absolute;
		min-width: 100px;
		&[aria-disabled="true"] {
			color: #585f69;
			cursor: default;
			pointer-events: none;
			.pagination__arrow {
				fill: #dcdbdc;
			}
		}
	}
	&__prev {
		left: 0;
		.pagination__arrow {
			transform: rotate(90deg);
			top: -2px;
			margin-right: 8px;
		}
	}
	&__next {
		right: 0;
		.pagination__arrow {
			transform: rotate(-90deg);
			top: -1px;
			margin-left: 8px;
		}
	}
	&__list {
		margin: auto;
		padding: 0;
		list-style: none;
		position: relative;
		@media #{$tablet-up} {
			text-align: center;
			width: 100%;
		}
	}
	&__pager {
		@media #{$tablet-down} {
			position: absolute;
			top: 5px;
			width: 45%;
			&.first {
				left: 0;
			}
			&.last {
				right: 0;
			}
		}
	}
	&__item {
		display: inline-block;
		position: relative;
		@media #{$tablet-down} {
			margin-bottom: 40px;
			top: 45px;
		}
		@media #{$tablet-up} {
			top: 7px;
		}
		&--active {
			display: inline-block;
			.pagination__link,
			.pagination__current {
				color: 414141;
				border-color: $dark-red;
			}
		} // @media #{$medium-up} {
		// 	display: inline-block;
		// }
	}
	&__link,
	&__current {
		@include chunk;
		color: #585f69;
		font-size: 2.3em;
		border-bottom: 5px solid transparent;
		min-width: 20px;
		text-align: center;
		padding-bottom: 6px;
		margin: 0 14px;
		display: block;
	}
	&__arrow {
		fill: $dark-red;
		position: relative;
	}
}
