.main-featured {
	@media #{$large-up} {
		display: flex;
		position: relative;
		z-index: 2;
		&:before,
		&:after {
			content: "";
			position: absolute;
			top: 0;
			bottom: 0;
			width: 100%;
			max-width: 445px;
		} // &:before {
		// 	left: -167px;
		// 	background: url(#{$img}left-gradient.png) repeat-y 0 0;
		// }
		// &:after {
		// 	right: -167px;
		// 	background: url(#{$img}right-gradient.png) repeat-y 100% 0;
		// }
	}
}

.article,
.hp-featured {
	color: #fff;
	position: relative;
	picture {
		position: relative;
		display: block;
		&:before {
			background-image: linear-gradient( 180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
			content: "";
			height: 50%;
			width: 100%;
			top: 50%;
			position: absolute;
		}
	}
	&__img {
		display: block;
		width: 100%;
	}
	&__title {
		@include chunk;
		font-size: 2.8em;
		font-weight: 400;
		line-height: 1.36;
		letter-spacing: 0.02px;
		margin: 13px 0 7px;
		@media #{$large-up} {
			font-size: 3em;
			line-height: 1.204545455;
			margin: 15px 0 4px;
		}
		a,
		a & {
			color: #fff;
			text-decoration: none;
			&:hover,
			&:focus {
				text-decoration: underline;
			}
		}
	}
	&__summary {
		@include ft-pro-500;
		font-size: 1.8em;
		line-height: 1.66;
		letter-spacing: 0.2px;
		color: rgba(#fff, 0.9);
		margin: 7px 0 20px;
		@media #{$large-up} {
			font-size: 2.3em;
			line-height: 1.47826087;
			margin: 0 0 15px;
		}
	}
	&--img {
		position: relative;
		margin-bottom: 15px;
		.article__link {
			position: relative;
			z-index: 2;
			display: block; //Dan
			&:after {
				content: "";
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				background-image: linear-gradient( 180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
			}
		}
		.article__title {
			position: absolute;
			bottom: 20px;
			left: 18px;
			right: 18px;
			font-size: 2.3em;
			z-index: 3;
			@media #{$medium-up} {
				bottom: 25px;
				padding-right: 10px;
			}
		}
		img {
			display: block;
			width: 100%;
		}
	}
}

.term-title {
	@include chunk;
	font-size: 3em;
	font-weight: 400;
	line-height: 1.36;
	letter-spacing: 0.02px;
	margin: 13px 0 7px;
	color: #000;
	@media #{$large-up} {
		font-size: 3.2em;
		line-height: 1.204545455;
		margin: 15px 0 4px;
	}
	a {
		color: $dark-red;
		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}
}

.hp-featured {
	img {
		display: block;
		width: 100%;
	}
	@media #{$large-up} {
		width: calc(100% - 494px);
		picture {
			visibility: hidden;
		}
	}
	&__content {
		@media (max-width:#{$mq-small}) {
			margin-top: -50%;
			padding-top: 4.5%;
		}
		margin-bottom: 49px;
	}
	&__content {
		z-index: 2;
		padding: 0 30px;
		position: relative;
		margin-top: -42%;
		@media #{$small-up2} {
			margin-top: -25%;
		}
		@media #{$large-up} {
			margin-top: -17%;
			max-width: 744px;
			float: right;
		}
		@media #{$xxlarge-up} {
			margin-right: 7%;
		}
	}
	&__category {
		@include fs-pro-600;
		font-size: 1.4em;
		line-height: 1;
		text-transform: uppercase;
		display: block;
		margin-bottom: 13px;
		position: relative;
		@media #{$large-up} {
			font-size: 1.6em;
			margin-bottom: 15px;
		}
	}
}

.--bg-light {
	.article {
		color: #2e3a44;
		.article__title,
		.article__title a {
			color: #000;
		}
		.article__summary {
			color: inherit;
		}
		.meta a {
			color: $dark-red;
		}
	}
}

.article--grid {
	margin-bottom: 26px;
	@media #{$large-up} {
		margin-bottom: 45px;
	}
	.article__title {
		font-size: 2.8em;
		line-height: 1.357142857;
		padding-right: 5px;
		@media #{$large-up} {
			font-size: 3em;
			line-height: 1.233333333;
			margin: 26px 0 9px;
		}
	}
	.article__summary {
		font-size: 1.8em;
		line-height: 1.722222222;
		@media #{$large-up} {
			font-size: 1.9em;
			line-height: 1.631578947;
		}
	}
	.meta__author {
		font-size: 1.7em;
		@media #{$large-up} {
			font-size: 1.8em;
		}
	}
}

.article--flex {
	@media #{$tablet-up} {
		display: flex;
		justify-content: space-between;
	}
	border-bottom: 1px solid rgba(#969696, 0.25);
	padding-bottom: 27px;
	margin-bottom: 24px;
	.article__title {
		@media #{$large-up} {
			margin: 36px 0 14px;
		}
	}
	.article__img {
		@media #{$tablet-up} {
			max-width: 275px;
			margin-right: 35px;
		}
		img {
			max-width: 150%;
			width: 100%;
		}
	}
	.article__content {
		@media #{$large-up} {
			width: calc(100% - 310px);
		}
	}
}

.article--cta {
	padding: 28px 0;
	@media #{$tablet-up} {
		justify-content: flex-start;
		align-items: center;
	}
	&:last-child {
		margin-bottom: 0;
		border-bottom: none;
	}
	.article__title {
		max-width: 530px;
		font-size: 2.8em;
		@media #{$large-up} {
			margin-top: 0;
			font-size: 4.2em;
		}
	}
}

.article-grid {
	margin-bottom: 20px;
	@media #{$medium-up} {
		margin-bottom: 38px;
		&__secondary {
			font-size: 1em;
		}
	}
	@media #{$small-up2} {
		&__secondary {
			display: flex;
			justify-content: space-between;
			.article {
				margin-right: 15px;
				&:last-child {
					margin-right: 0;
				}
			}
		}
	}
	@media #{$large-up} {
		display: flex;
		justify-content: space-between;
		&__main {
			margin-right: 15px;
			margin-bottom: 15px;
			.flex-l & {
				margin-right: 0;
			}
			.article__title {
				font-size: 4.4em;
				line-height: 1.204545455;
				left: 30px;
				right: 65px;
				bottom: 25px;
			}
		}
		&__secondary {
			display: block;
			font-size: 0.9em;
			.article {
				margin-right: 0;
			}
		}
	}
	&__main {}
	&__secondary {}
}

.archives {
	background: #fff;
	padding: 70px 0;
	padding: 36px 0 57px;
	&__wrap {
		@media #{$large-up} {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
		}
	}
	.listing--image {
		margin-bottom: 2.316667em; // margin-right:2.916666667%;
		@media #{$large-up} {
			max-width: calc(25% - (2.916666667% / 1.5));
			flex-grow: 1;
			&:nth-child(4n + 4) {
				margin-right: 0;
			}
		}
	}
	&__title {
		@include chunk;
		color: #000;
		font-size: 2.7em;
		text-align: center;
		line-height: 1;
		margin: 0 0 45px;
		span {
			display: inline-block;
			vertical-align: middle;
			position: relative;
			&:before,
			&:after {
				content: "•";
				color: #b22a39;
				font-size: 1em;
				position: relative;
				display: inline-block;
				vertical-align: middle;
			}
			&:before {
				left: -20px;
			}
			&:after {
				right: -20px;
			}
		}
	}
}
