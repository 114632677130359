.blog-list {
	@media #{$small-up2} {
		display: flex; //justify-content: space-between;
		justify-content: center; //flex-grow: 1;
		flex-wrap: wrap;
	}
	@media #{$xlarge-up} {
		flex-wrap: nowrap;
	}
}

.blogs {
	@media #{$tablet-down} {
		margin-bottom: 50px;
	}
	.listing__image {
		img {
			width: 100%;
		}
	}
	@media #{$small-up2} {
		width: 275px;
		&:nth-child(odd) {
			margin-right: 2.916666667%;
		}
		&:last-child {
			margin-right: 0;
		}
	}
	@media #{$tablet-up} {
		margin-right: 2.916666667%;
		&:last-child {
			margin-right: 0;
		}
	}
	@media #{$large-up} {
		margin-right: 1%;
		&:nth-child(odd) {
			margin-right: 1%;
		}
		&:last-child {
			margin-right: 0;
		}
	}
	&__title {
		color: #ffffff;
		@include ft-pro-600;
		font-size: 3.1em;
		line-height: 1.870967742;
		letter-spacing: 0.02px;
		@media #{$medium-up} {
			font-size: 2.1em;
			margin-bottom: 32px;
		}
		&:after {
			content: '';
			height: 2px;
			width: 27px;
			background: $dark-red;
			display: block;
		}
		a {
			color: #FFF;
			&:hover,
			&:focus {
				text-decoration: underline;
			}
		}
	}
	&__all {
		padding: 25px 0;
		display: block;
		@include fs-pro-600;
		color: #ffffff;
		font-size: 1.4em;
		&:hover,
		&:focus {
			text-decoration: underline;
			color: #fff;
		}
		@media #{$large-up} {
			position: relative;
			top: 12px;
		}
	}
}

.bx-wrap {
	max-width: 385px;
	margin: auto;
	@media #{$small-up2} {
		max-width: 100%;
	}
	@media #{$large-up} {
		max-width: 10000px;
	}
}

.blog-list-bx {
	padding-bottom: 37px; // @media #{$medium-down} {
	// 	.blog-list {
	// 		display: block;
	// 	}
	// }
	@media #{$large-up} {
		padding-bottom: 60px;
	}
}
