.footer {
	>.wrap {
		position: relative;
		z-index: 2;
	}
	.wrap {
		background: #000;
		@media #{$medium-up} {
			padding: 46px 30px 0;
		}
	}
	&__large {
		@media #{$medium-down} {
			display: none;
		}
	}
	&__flex {
		display: flex;
		justify-content: space-between;
		flex-direction: column-reverse;
		@media #{$large-up} {
			flex-direction: row;
		}
	}
	&__wrap {
		display: flex;
		justify-content: space-between;
	}
	&__mag {
		@media #{$medium-down} {
			margin: auto;
			margin-bottom: -15em;
			max-width: 233px;
		}
		@media #{$medium-up} {
			width: 23.4166667%;
			margin-bottom: -4.5em;
		}
		position:relative;
		z-index: 2px;
	}
	&__links__wrap,
	&__secondary,
	&__main {
		display: flex;
		justify-content: space-between;
	}
	&__list {
		margin: 0;
		padding: 0;
		list-style: none;
	}
	&__links {
		@media #{$large-up} {
			width: 73.75%;
		}
	}
	&__secondary {
		width: 32.203389831%;
		.footer__link {
			color: #ffffff;
			@include fs-pro-700;
			font-size: 1.3em;
			line-height: 1.615384615;
			letter-spacing: 0.2px;
			text-transform: uppercase;
			padding: 5px 0;
			margin: 4px 0 10px;
			display: block;
			&:hover,
			&:focus {
				text-decoration: underline;
			}
		}
		.footer__column:last-child {
			margin-left: 40px;
		}
	}
	.footer__title {
		color: #ffffff;
		@include chunk;
		font-size: 2.4em;
		line-height: 1.666666667;
		margin: -2px 0 9px;
		&:after {
			content: "";
			height: 2px;
			width: 27px;
			background: $dark-red;
			display: block;
		}
	}
	&__main {
		width: 64.971751412%;
		padding-left: 3%;
		.footer__column {
			border-left: 1px solid rgba(#969696, 0.3);
			padding-left: 35px;
			width: 25%;
			margin-right: 4%;
			&:last-child {
				margin-right: 0;
			}
		}
		.footer__link {
			color: rgba(#fff, 0.7);
			@include fs-pro-600;
			font-size: 1.4em;
			letter-spacing: 0.22px;
			display: block;
			padding: 5px 0;
			margin-bottom: 6px;
			&:focus,
			&:hover {
				text-decoration: underline;
				color: #fff;
			}
		}
	}
}

.newsletter {
	padding-top: 20px;
	border-top: 1px solid rgba(#969696, 0.3);
	@media #{$medium-down} {
		margin-left: -30px;
		margin-right: -30px;
	}
	@media #{$large-up} {
		display: flex;
		justify-content: space-between
	}
	&__cfni {
		img {
			display: block;
			max-width: 221px;
			@media #{$medium-down} {
				margin: auto;
			}
		}
	}
	&__form {
		display: block;
		padding-top: 38px;
		@media #{$medium-down} {
			padding-left: 30px;
			padding-right: 30px;
		}
		@media #{$large-up} {
			padding-top: 0;
		}
	}
	fieldset {
		border: none;
		width: 100%;
	}
	&__flex {
		@media #{$large-up} {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
		}
	}
	&__legend {
		border: none;
		@include chunk;
		color: #fff;
		padding: 0;
		margin: 0;
		font-size: 2.1em;
		padding-top: 4px;
		text-align: center;
		margin-bottom: 17px;
		@media (min-width: 1000px) and (max-width: 1125px) {
			font-size: 1.8em;
		}
		@media #{$large-up} {
			width: 100%;
			text-align: left;
			margin-bottom: 0;
			flex-grow: 2;
			line-height: 1;
			position: relative;
			top: -3px;
			margin-bottom: 3px;
		}
	}
	&__inputs {
		@media #{$large-up} {
			width: 100%;
			text-align: right;
			display: flex;
			position: relative;
			top: -4px;
		}
	}
	&__input {
		border-radius: 2px;
		border: 1px solid #adadad;
		background-color: #fff;
		color: #000;
		width: 100%;
		height: 43px;
		font-size: 1.4em;
		@include fs-pro;
		@include placeholder {
			font-style: italic;
			color: #9b9b9b;
		}
		@media #{$medium-down} {
			box-sizing: border-box;
			text-align: center;
			margin-bottom: 10px;
		}
		@media #{$large-up} {
			height: 25px;
			max-width: 385px;
		}
	}
	@media #{$medium-down} {
		.btn--primary {
			width: 100%;
			margin: 0 0 50px;
		}
	}
}

.social {
	font-family: "Socialico";
}

.footer__sub {
	.wrap {
		background-color: #191919;
		@media #{$medium-up} {
			padding: 0 30px;
		}
		@media #{$medium-down} {
			// padding-top: 46%;
			padding-top: 165px;
		}
	}
	&__contain {
		@media (min-width: 1000px) and (max-width: 1099px) {
			padding: 13px 0;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		@media (min-width: 1100px) {
			padding: 13px 0 13px 23.4166667%;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}
	p {
		color: #ababab;
		@include fs-pro-600;
		font-size: 1.5em;
		line-height: 1.866666667;
		letter-spacing: 0.2px;
		text-align: center;
		@media #{$large-up} {
			font-size: 1.3em;
			line-height: 2.153846154;
			padding-left: 3.833333%;
			text-align: left;
		}
		a {
			color: #fff;
			text-decoration: underline;
			&:hover,
			&:focus {
				text-decoration: none;
			}
		}
	}
	&__links {
		margin: auto auto 31px;
		padding: 0;
		list-style: none;
		text-align: center;
		@media #{$large-up} {
			//margin:0 0 0 -6%;
			margin: auto auto auto 40px;
			text-align: left;
		}
	}
	&__item {
		position: relative;
		display: inline-block;
		vertical-align: middle;
		margin-right: 30px;
		&:last-child {
			margin-right: 0;
		}
		@media #{$large-down} {
			&:first-child {
				&:before {
					display: none;
				}
			}
		}
		&:before {
			content: "";
			height: 13px;
			width: 1px;
			background: rgba(#969696, 0.3);
			position: absolute;
			left: -20px;
			top: 50%;
			transform: translateY(-50%);
		}
	}
	&__link {
		color: #ababab;
		@include fs-pro-600;
		font-size: 1.3em;
		line-height: 2.153846154;
		letter-spacing: 0.2px;
		&:hover,
		&:focus {
			text-decoration: underline;
			color: #fff;
		}
	}
}

.social-links {
	padding: 0;
	list-style: none;
	width: auto;
	margin: auto;
	text-align: center;
	@media #{$large-up} {
		margin: 0;
		text-align: left;
	}
	&__item {
		display: inline-block;
		vertical-align: middle;
		margin-left: 28px;
		@media #{$large-down} {
			&:first-child {
				margin-left: 0;
			}
		}
	}
	&__link {
		color: #fff;
		font-size: 2.6em;
		line-height: 1.269230769;
		text-transform: uppercase;
		&:focus,
		&:hover {
			color: $dark-red;
		}
	}
}

.footer__small {
	display: none;
	background: #000;
	@media #{$medium-down} {
		display: block;
	}
	&__column {
		text-align: center;
	}
	&__title {
		margin: 0;
		font-size: 1em;
		line-height: 1;
	}
	&__btn {
		color: #ffffff;
		@include chunk;
		font-size: 2.3em;
		background: #000;
		width: 100%;
		display: block;
		border: 1px solid rgba(#969696, 0.3);
		margin: 0;
		padding: 31px 5px 25px;
		svg {
			fill: $dark-red;
			transition: all 0.25s;
		}
		&--open {
			svg {
				fill: #fff;
				transform: rotate(180deg);
			}
		}
	}
	&__toggle {
		display: none;
		max-height: 0;
		transition: all 0.25s;
		overflow: hidden;
		&--open {
			max-height: 2000px;
		}
		&--block {
			display: block;
		}
	}
	&__list {
		background-color: #1a1a1a;
		padding: 14px 0 9px;
		margin: 0;
		list-style: none;
	}
	&__item {
		padding: 7px 0;
	}
	&__link {
		opacity: 0.85;
		color: rgba(#fff, 0.85);
		@include fs-pro-600;
		font-size: 1.7em;
		letter-spacing: 0.22px;
		display: block;
		padding: 10px 5px;
	}
	&__arrow {
		position: relative;
		top: -2px;
		left: 2px;
		fill: $dark-red;
		transition: all 0.25s;
	}
	&__sub-link {
		color: #fff;
		display: block;
		text-align: center;
		text-transform: uppercase;
		@include fs-pro-700;
		font-size: 1.5em;
		line-height: 1.2;
		letter-spacing: 0.2px;
		padding: 10px;
	}
	&__sub-list {
		padding: 27px 0 0;
		margin: 0;
	}
	&__sub-item {
		padding-bottom: 10px;
	}
}

#sliding-popup {
	.popup-content {
		@extend .wrap;
		#popup-text {
			max-width: 90%;
			margin-top: 13px;
		}
		#popup-buttons {
			max-width: 10%;
		}
	}
}

#sliding-popup div.popup-content {
	text-align: left;
	padding-top: 5px;
	padding-bottom: 5px;
}

#sliding-popup .popup-content div#popup-text {
	float: none;
	max-width: calc(100% - 65px);
	display: inline-block;
	vertical-align: middle;
	p {
		text-align: left;
	}
}

#sliding-popup .popup-content div#popup-buttons {
	float: none;
	margin: 0 0 0px 20px;
	display: inline-block;
	vertical-align: middle;
}

#sliding-popup .eu-cookie-compliance-default-button.eu-cookie-compliance-default-button {
	border: none;
	background: none;
	border-radius: 0;
	box-shadow: none;
	transition: all .15s;
	&:hover,
	&:focus {
		background: #000;
	}
}
