.webform-client-form {
	.form-item {
		margin: 25px 0 10px;
	}
	.form-required {
		color: $dark-red;
	}
	input:not([type=submit]):not([type=file]),
	textarea {
		border-radius: 2px;
		border: 1px solid #adadad;
		background-color: #fff;
		color: #000;
		width: 100%;
		height: 43px;
		font-size: 1.4em;
		@include fs-pro;
		&::-webkit-input-placeholder {
			font-style: italic;
			color: #9b9b9b;
		}
		&:-moz-placeholder {
			font-style: italic;
			color: #9b9b9b;
		}
		&::-moz-placeholder {
			font-style: italic;
			color: #9b9b9b;
		}
		&:-ms-input-placeholder {
			font-style: italic;
			color: #9b9b9b;
		}
		@media #{$medium-down} {
			box-sizing: border-box;
			text-align: center;
			margin-bottom: 10px;
		}
		@media #{$large-up} {
			height: 25px;
			max-width: 500px;
		}
	}
	textarea {
		height: 150px;
		@media #{$large-up} {
			height: 150px;
		}
	}
	select {
		border-radius: 2px;
		border: 1px solid #adadad;
		background-color: #fff;
		color: #000;
		width: 100%;
		height: 43px;
		padding: 0.625em 1em;
		font-size: 1.4em;
		@include fs-pro;
		max-width: 530px;
	}
	.webform-submit,
	input[type="submit"] {
		@extend .btn;
		@extend .btn--primary;
		margin-left: 0;
	}
	label {
		@include chunk;
		color: #000;
		padding: 4px 0 0;
		font-size: 2.1em;
		text-align: left;
		display: block;
		margin-bottom: 10px;
	}
}

.form-dark .webform-client-form,
.form-dark.webform-client-form {
	label {
		color: #FFF;
	}
}

.view-filters {
	margin-bottom: 40px;
}

.views-exposed-widget {
	@media #{$tablet-up} {
		display: inline-block;
		vertical-align: middle;
	}
	label {
		font-weight: bold;
		color: #000;
		padding: 4px 0 0;
		font-size: 1.8em;
		text-align: left;
		display: block;
		margin-bottom: 10px;
		@media #{$tablet-up} {
			display: inline-block;
			vertical-align: middle;
			margin-bottom: 0;
		}
		+.views-widget {
			display: block;
			margin-bottom: 10px;
			@media #{$tablet-up} {
				display: inline-block;
				vertical-align: middle;
				margin-left: 10px;
				margin-bottom: 0;
			}
		}
	}
	select {
		font-size: 1.8em;
		display: block;
		margin-bottom: 10px;
		@media #{$tablet-up} {
			display: inline-block;
			vertical-align: middle;
			margin-right: 10px;
			margin-bottom: 0;
		}
	}
	input[type="submit"] {
		@extend .btn;
		@extend .btn--primary;
		display: inline-block;
		vertical-align: middle;
	}
}
