$font-stack-system: -apple-system,
BlinkMacSystemFont,
"Segoe UI",
"Roboto",
"Oxygen",
"Ubuntu",
"Cantarell",
"Fira Sans",
"Droid Sans",
"Helvetica Neue",
sans-serif !default;
$base-font-family: $font-stack-system;
$base-font-size: 62.5%;
$base-line-height: 1;
// bourbon variable
$em-base: strip-units($base-font-size);
$fpath:'../fonts/';

$chunk-path:$fpath + 'chunk/';
@font-face {
	font-family: 'Chunk';
	src: url(#{$chunk-path}chunkfive_roman_regular-webfont.eot);
	src: url(#{$chunk-path}chunkfive_roman_regular-webfont.eot?#iefix) format('embedded-opentype'),
	url(#{$chunk-path}chunkfive_roman_regular-webfont.woff) format('woff'),
	url(#{$chunk-path}chunkfive_roman_regular-webfont.ttf) format('truetype'),
	url(#{$chunk-path}chunkfive_roman_regular-webfont.svg#chunkfiveroman) format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: fallback;
}

$merriweather-path: $fpath + 'merriweather-v21-latin/';
/* merriweather-300 - latin */
@font-face {
	font-family: 'Merriweather';
	font-style: normal;
	font-weight: 300;
	font-display: fallback;
	src: url('#{$merriweather-path}merriweather-v21-latin-300.eot'); /* IE9 Compat Modes */
	src: local('Merriweather Light'), local('Merriweather-Light'),
		 url('#{$merriweather-path}merriweather-v21-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('#{$merriweather-path}merriweather-v21-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
		 url('#{$merriweather-path}merriweather-v21-latin-300.woff') format('woff'), /* Modern Browsers */
		 url('#{$merriweather-path}merriweather-v21-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('#{$merriweather-path}merriweather-v21-latin-300.svg#Merriweather') format('svg'); /* Legacy iOS */
  }
  /* merriweather-300italic - latin */
  @font-face {
	font-family: 'Merriweather';
	font-style: italic;
	font-weight: 300;
	font-display: fallback;
	src: url('#{$merriweather-path}merriweather-v21-latin-300italic.eot'); /* IE9 Compat Modes */
	src: local('Merriweather Light Italic'), local('Merriweather-LightItalic'),
		 url('#{$merriweather-path}merriweather-v21-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('#{$merriweather-path}merriweather-v21-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
		 url('#{$merriweather-path}merriweather-v21-latin-300italic.woff') format('woff'), /* Modern Browsers */
		 url('#{$merriweather-path}merriweather-v21-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('#{$merriweather-path}merriweather-v21-latin-300italic.svg#Merriweather') format('svg'); /* Legacy iOS */
  }
  /* merriweather-regular - latin */
  @font-face {
	font-family: 'Merriweather';
	font-style: normal;
	font-weight: 400;
	font-display: fallback;
	src: url('#{$merriweather-path}merriweather-v21-latin-regular.eot'); /* IE9 Compat Modes */
	src: local('Merriweather Regular'), local('Merriweather-Regular'),
		 url('#{$merriweather-path}merriweather-v21-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('#{$merriweather-path}merriweather-v21-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
		 url('#{$merriweather-path}merriweather-v21-latin-regular.woff') format('woff'), /* Modern Browsers */
		 url('#{$merriweather-path}merriweather-v21-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('#{$merriweather-path}merriweather-v21-latin-regular.svg#Merriweather') format('svg'); /* Legacy iOS */
  }
  /* merriweather-italic - latin */
  @font-face {
	font-family: 'Merriweather';
	font-style: italic;
	font-weight: 400;
	font-display: fallback;
	src: url('#{$merriweather-path}merriweather-v21-latin-italic.eot'); /* IE9 Compat Modes */
	src: local('Merriweather Italic'), local('Merriweather-Italic'),
		 url('#{$merriweather-path}merriweather-v21-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('#{$merriweather-path}merriweather-v21-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
		 url('#{$merriweather-path}merriweather-v21-latin-italic.woff') format('woff'), /* Modern Browsers */
		 url('#{$merriweather-path}merriweather-v21-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('#{$merriweather-path}merriweather-v21-latin-italic.svg#Merriweather') format('svg'); /* Legacy iOS */
  }
  /* merriweather-700 - latin */
  @font-face {
	font-family: 'Merriweather';
	font-style: normal;
	font-weight: 700;
	font-display: fallback;
	src: url('#{$merriweather-path}merriweather-v21-latin-700.eot'); /* IE9 Compat Modes */
	src: local('Merriweather Bold'), local('Merriweather-Bold'),
		 url('#{$merriweather-path}merriweather-v21-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('#{$merriweather-path}merriweather-v21-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
		 url('#{$merriweather-path}merriweather-v21-latin-700.woff') format('woff'), /* Modern Browsers */
		 url('#{$merriweather-path}merriweather-v21-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('#{$merriweather-path}merriweather-v21-latin-700.svg#Merriweather') format('svg'); /* Legacy iOS */
  }
  /* merriweather-700italic - latin */
  @font-face {
	font-family: 'Merriweather';
	font-style: italic;
	font-weight: 700;
	font-display: fallback;
	src: url('#{$merriweather-path}merriweather-v21-latin-700italic.eot'); /* IE9 Compat Modes */
	src: local('Merriweather Bold Italic'), local('Merriweather-BoldItalic'),
		 url('#{$merriweather-path}merriweather-v21-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('#{$merriweather-path}merriweather-v21-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
		 url('#{$merriweather-path}merriweather-v21-latin-700italic.woff') format('woff'), /* Modern Browsers */
		 url('#{$merriweather-path}merriweather-v21-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('#{$merriweather-path}merriweather-v21-latin-700italic.svg#Merriweather') format('svg'); /* Legacy iOS */
  }
  /* merriweather-900 - latin */
  @font-face {
	font-family: 'Merriweather';
	font-style: normal;
	font-weight: 900;
	font-display: fallback;
	src: url('#{$merriweather-path}merriweather-v21-latin-900.eot'); /* IE9 Compat Modes */
	src: local('Merriweather Black'), local('Merriweather-Black'),
		 url('#{$merriweather-path}merriweather-v21-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('#{$merriweather-path}merriweather-v21-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
		 url('#{$merriweather-path}merriweather-v21-latin-900.woff') format('woff'), /* Modern Browsers */
		 url('#{$merriweather-path}merriweather-v21-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('#{$merriweather-path}merriweather-v21-latin-900.svg#Merriweather') format('svg'); /* Legacy iOS */
  }
  /* merriweather-900italic - latin */
  @font-face {
	font-family: 'Merriweather';
	font-style: italic;
	font-weight: 900;
	font-display: fallback;
	src: url('#{$merriweather-path}merriweather-v21-latin-900italic.eot'); /* IE9 Compat Modes */
	src: local('Merriweather Black Italic'), local('Merriweather-BlackItalic'),
		 url('#{$merriweather-path}merriweather-v21-latin-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('#{$merriweather-path}merriweather-v21-latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
		 url('#{$merriweather-path}merriweather-v21-latin-900italic.woff') format('woff'), /* Modern Browsers */
		 url('#{$merriweather-path}merriweather-v21-latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('#{$merriweather-path}merriweather-v21-latin-900italic.svg#Merriweather') format('svg'); /* Legacy iOS */
  }

$source-sans-pro-path: $fpath + 'source-sans-pro-v13-latin/';
/* source-sans-pro-regular - latin */
@font-face {
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 400;
	font-display: fallback;
	src: url('#{$source-sans-pro-path}source-sans-pro-v13-latin-regular.eot'); /* IE9 Compat Modes */
	src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'),
		 url('#{$source-sans-pro-path}source-sans-pro-v13-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('#{$source-sans-pro-path}source-sans-pro-v13-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
		 url('#{$source-sans-pro-path}source-sans-pro-v13-latin-regular.woff') format('woff'), /* Modern Browsers */
		 url('#{$source-sans-pro-path}source-sans-pro-v13-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('#{$source-sans-pro-path}source-sans-pro-v13-latin-regular.svg#SourceSansPro') format('svg'); /* Legacy iOS */
  }
  /* source-sans-pro-italic - latin */
  @font-face {
	font-family: 'Source Sans Pro';
	font-style: italic;
	font-weight: 400;
	font-display: fallback;
	src: url('#{$source-sans-pro-path}source-sans-pro-v13-latin-italic.eot'); /* IE9 Compat Modes */
	src: local('Source Sans Pro Italic'), local('SourceSansPro-Italic'),
		 url('#{$source-sans-pro-path}source-sans-pro-v13-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('#{$source-sans-pro-path}source-sans-pro-v13-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
		 url('#{$source-sans-pro-path}source-sans-pro-v13-latin-italic.woff') format('woff'), /* Modern Browsers */
		 url('#{$source-sans-pro-path}source-sans-pro-v13-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('#{$source-sans-pro-path}source-sans-pro-v13-latin-italic.svg#SourceSansPro') format('svg'); /* Legacy iOS */
  }
  /* source-sans-pro-600 - latin */
  @font-face {
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 600;
	font-display: fallback;
	src: url('#{$source-sans-pro-path}source-sans-pro-v13-latin-600.eot'); /* IE9 Compat Modes */
	src: local('Source Sans Pro SemiBold'), local('SourceSansPro-SemiBold'),
		 url('#{$source-sans-pro-path}source-sans-pro-v13-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('#{$source-sans-pro-path}source-sans-pro-v13-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
		 url('#{$source-sans-pro-path}source-sans-pro-v13-latin-600.woff') format('woff'), /* Modern Browsers */
		 url('#{$source-sans-pro-path}source-sans-pro-v13-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('#{$source-sans-pro-path}source-sans-pro-v13-latin-600.svg#SourceSansPro') format('svg'); /* Legacy iOS */
  }
  /* source-sans-pro-700 - latin */
  @font-face {
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 700;
	font-display: fallback;
	src: url('#{$source-sans-pro-path}source-sans-pro-v13-latin-700.eot'); /* IE9 Compat Modes */
	src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'),
		 url('#{$source-sans-pro-path}source-sans-pro-v13-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('#{$source-sans-pro-path}source-sans-pro-v13-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
		 url('#{$source-sans-pro-path}source-sans-pro-v13-latin-700.woff') format('woff'), /* Modern Browsers */
		 url('#{$source-sans-pro-path}source-sans-pro-v13-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('#{$source-sans-pro-path}source-sans-pro-v13-latin-700.svg#SourceSansPro') format('svg'); /* Legacy iOS */
  }

.chunk,
%chunk {
	font-family: 'Chunk';
	font-weight: normal;
	font-style: normal;
}

@mixin chunk {
	font-family: 'Chunk';
	font-weight: normal;
	font-style: normal;
}

.fs-pro,
%fs-pro {
	//font-family: 'freight-sans-pro';
	//font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
	font-family: Helvetica, Arial, sans-serif;
	font-style: normal;
}

@mixin fs-pro {
	//font-family: 'freight-sans-pro';
	//font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
	font-family: Helvetica, Arial, sans-serif;
	font-style: normal;
}

.fs-pro-med,
.fs-pro-500,
%fs-pro-med,
%fs-pro-500 {
	//	font-family: 'freight-sans-pro';
	//font-weight: 500;
	//font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
	font-family: Helvetica, Arial, sans-serif;
	font-style: normal;
	font-weight: 400;
}

@mixin fs-pro-500 {
	//font-family: 'freight-sans-pro';
	//font-weight: 500;
	//font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
	font-family: Helvetica, Arial, sans-serif;
	font-style: normal;
	font-weight: 400;
}

.fs-pro-med-i,
.fs-pro-500-i,
%fs-pro-med-i,
%fs-pro-500-i {
	//font-family: 'freight-sans-pro';
	//font-weight: 500;
	//font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
	font-family: Helvetica, Arial, sans-serif;
	font-style: italic;
	font-weight: 400;
}

@mixin fs-pro-500-i {
	//font-family: 'freight-sans-pro';
	//font-weight: 500;
	//font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
	font-family: Helvetica, Arial, sans-serif;
	font-style: italic;
	font-weight: 400;
}

.fs-pro-semi,
.fs-pro-600,
%fs-pro-semi,
%fs-pro-600 {
	//font-family: 'freight-sans-pro';
	//font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
	font-family: Helvetica, Arial, sans-serif;
	font-style: normal;
	font-weight: 600;
}

@mixin fs-pro-600 {
	//font-family: 'freight-sans-pro';
	//font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
	font-family: Helvetica, Arial, sans-serif;
	font-style: normal;
	font-weight: 600;
}

.fs-pro-semi,
.fs-pro-700,
%fs-pro-semi,
%fs-pro-700 {
	//font-family: 'freight-sans-pro';
	//font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
	font-family: Helvetica, Arial, sans-serif;
	font-style: normal;
	font-weight: 700;
}

@mixin fs-pro-700 {
	//font-family: 'freight-sans-pro';
	//font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
	font-family: Helvetica, Arial, sans-serif;
	font-style: normal;
	font-weight: 700;
}

.fd-pro-semi,
.fd-pro-700,
%fd-pro-semi,
%fd-pro-700 {
	font-family: 'freight-display-pro';
	font-style: normal;
	font-weight: 700;
}

@mixin fd-pro-700 {
	//font-family: 'freight-display-pro';
	//font-weight: 700;
	//font-family: 'Merriweather', serif;
	font-family: "Georgia", "Palatino Linotype", "Book Antiqua", "Palatino", "Times New Roman", Times, serif;
	font-style: normal;
	font-weight: 600;
}

.ft-pro,
%ft-pro {
	//font-family: 'freight-text-pro';
	//font-family: 'Merriweather', serif;
	font-family: "Georgia", "Palatino Linotype", "Book Antiqua", "Palatino", "Times New Roman", Times, serif;
}

@mixin ft-pro {
	//font-family: 'freight-text-pro';
	//font-family: 'Merriweather', serif;
	font-family: "Georgia", "Palatino Linotype", "Book Antiqua", "Palatino", "Times New Roman", Times, serif;
}

.ft-pro-light-i,
%ft-pro-light-i,
.ft-pro-300-i,
%ft-pro-300-i {
	//font-family: 'freight-text-pro';
	//font-family: 'Merriweather', serif;
	font-family: "Georgia", "Palatino Linotype", "Book Antiqua", "Palatino", "Times New Roman", Times, serif;
	font-weight: 300;
	font-style: italic;
}

@mixin ft-pro-300-i {
	//font-family: 'freight-text-pro';
	//font-family: 'Merriweather', serif;
	font-family: "Georgia", "Palatino Linotype", "Book Antiqua", "Palatino", "Times New Roman", Times, serif;
	font-weight: 300;
	font-style: italic;
}

.ft-pro-light,
%ft-pro-light,
.ft-pro-300,
%ft-pro-300 {
	//font-family: 'freight-text-pro';
	//font-family: 'Merriweather', serif;
	font-family: "Georgia", "Palatino Linotype", "Book Antiqua", "Palatino", "Times New Roman", Times, serif;
	font-weight: 300;
	font-style: normal;
}

@mixin ft-pro-300 {
	//font-family: 'freight-text-pro';
	//font-family: 'Merriweather', serif;
	font-family: "Georgia", "Palatino Linotype", "Book Antiqua", "Palatino", "Times New Roman", Times, serif;
	font-weight: 300;
	font-style: normal;
}

.ft-pro-med,
%ft-pro-med,
.ft-pro-500,
%ft-pro-500 {
	//font-family: 'freight-text-pro';
	//font-weight: 500;
	//font-family: 'Merriweather', serif;
	font-family: "Georgia", "Palatino Linotype", "Book Antiqua", "Palatino", "Times New Roman", Times, serif;
	font-weight: 400;
	font-style: normal;
}

@mixin ft-pro-500 {
	//font-family: 'freight-text-pro';
	//font-weight: 500;
	//font-family: 'Merriweather', serif;
	font-family: "Georgia", "Palatino Linotype", "Book Antiqua", "Palatino", "Times New Roman", Times, serif;
	font-weight: 400;
	font-style: normal;
}

.ft-pro-med-i,
%ft-pro-med-i,
.ft-pro-500-i,
%ft-pro-500-i {
	//font-family: 'freight-text-pro';
	//font-weight: 500;
	//font-family: 'Merriweather', serif;
	font-family: "Georgia", "Palatino Linotype", "Book Antiqua", "Palatino", "Times New Roman", Times, serif;
	font-weight: 400;
	font-style: italic;
}

@mixin ft-pro-500-i {
	//font-family: 'freight-text-pro';
	//font-weight: 500;
	//font-family: 'Merriweather', serif;
	font-family: "Georgia", "Palatino Linotype", "Book Antiqua", "Palatino", "Times New Roman", Times, serif;
	font-weight: 400;
	font-style: italic;
}

.ft-pro-semi,
%ft-pro-semi,
.ft-pro-600,
%ft-pro-600 {
	//font-family: 'freight-text-pro';
	//font-weight: 600;
	//font-family: 'Merriweather', serif;
	font-family: "Georgia", "Palatino Linotype", "Book Antiqua", "Palatino", "Times New Roman", Times, serif;
	font-weight: 700;
	font-style: normal;
}

@mixin ft-pro-600 {
	//font-family: 'freight-text-pro';
	//font-weight: 600;
	//font-family: 'Merriweather', serif;
	font-family: "Georgia", "Palatino Linotype", "Book Antiqua", "Palatino", "Times New Roman", Times, serif;
	font-weight: 700;
	font-style: normal;
}

.ft-pro-black,
%ft-pro-black,
.ft-pro-900,
%ft-pro-900 {
	//font-family: 'freight-text-pro';
	//font-family: 'Merriweather', serif;
	font-family: "Georgia", "Palatino Linotype", "Book Antiqua", "Palatino", "Times New Roman", Times, serif;
	font-weight: 900;
	font-style: normal;
}

@mixin ft-pro-900 {
	//	font-family: 'freight-text-pro';
	//font-family: 'Merriweather', serif;
	font-family: "Georgia", "Palatino Linotype", "Book Antiqua", "Palatino", "Times New Roman", Times, serif;
	font-weight: 900;
	font-style: normal;
}

/* @font-face kit by Fonts2u (http://www.fonts2u.com) */

@font-face {
	font-family: "Socialico";
	src: url("#{$fpath}Socialico/Socialico.eot?") format("eot"), url("#{$fpath}Socialico/Socialico.woff") format("woff"), url("#{$fpath}Socialico/Socialico.ttf") format("truetype"), url("#{$fpath}Socialico/Socialico.svg#Socialico") format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: fallback;
}
