main {
	position: relative;
	z-index: 2;
	margin-top: 20px;
	overflow-x: hidden;
	&.--extra-top {
		>*:first-child {
			padding-top: 16px;
			@media #{$large-up} {
				padding-top: 59px;
			}
		}
	}
}
