.latest {
	padding: 51px 0 0;
	color: #fff;
	.wrap {
		background: #000;
		@media #{$medium-down} {
			padding-top: 20px;
			padding-bottom: 30px;
		}
	}
	@media #{$large-up} {
		padding: 51px 0;
	}
	&__content {
		margin-bottom: 28px;
		@media #{$large-up} {
			width: 60.544218%;
			padding-top: 58px;
		}
	}
	&__title {
		@include chunk;
		font-size: 2.8em;
		line-height: 1.357142857;
		letter-spacing: 0.02px;
		color: #fff;
		padding-right: 5px;
		margin-bottom: 10px;
		@media #{$large-up} {
			font-size: 4em;
			line-height: 1.45;
			margin-top: 0;
		}
	}
	&__summary {
		@include ft-pro-500;
		font-size: 1.9em;
		color: rgba(#ffffff, 0.85);
		line-height: 1.894736842;
		letter-spacing: 0.02px;
		margin: 0 0 33px;
		@media #{$large-up} {
			font-size: 2.2em;
			line-height: 1.681818182;
			margin: 0 0 27px;
		}
	}
	&__cover {
		//margin-bottom:-35px;
		img {
			display: block;
			margin: auto;
		}
		@media #{$tablet-up} {
			//margin-bottom:-50px;
			position: relative;
		}
		@media #{$large-up} {
			width: 34.707482993%;
			margin-bottom: auto;
			img {
				margin: 0;
			}
		}
		@media (min-width: 1300px) {
			img {
				//     margin:0 0 0 41px
			}
		}
	}
	picture {
		display: none;
	}
	&__tri {
		width: 264px;
		margin: 40px auto auto;
		position: relative;
		display: block;
		@media #{$large-up} {
			width: 245px;
		}
	}
	&__1,
	&__2,
	&__3 {
		display: block;
		img {
			display: block;
		}
		@media (min-width: 1300px) {
			margin: 0 0 0 41px;
		}
	}
	&__2,
	&__3 {
		position: absolute;
	}
	&__1 {
		width: 180px;
		margin-top: 15px;
		z-index: 3;
		position: relative;
		@media #{$large-up} {
			width: 245px;
		}
	}
	&__2 {
		width: 180px;
		transform: rotate(13deg);
		z-index: 2;
		left: 45px;
		top: 0px;
		background-color: #000;
		img {
			opacity: 0.5;
		}
		@media #{$large-up} {
			width: 208px;
			left: 73px;
			top: 31px;
		}
	}
	&__3 {
		width: 180px;
		transform: rotate(32deg);
		background-color: #000;
		left: 80px;
		top: 0px;
		img {
			opacity: 0.3;
		}
		@media #{$large-up} {
			width: 176px;
			left: 140px;
			top: 70px;
		}
	}
	.title-bleed {
		color: #fff;
		font-size: 1.7em;
		margin-bottom: 20px;
		@media #{$large-up} {
			font-size: 2em;
		}
	}
	&__wrap {
		@media #{$large-up} {
			display: flex;
			justify-content: space-between;
		}
	}
}

.magazine {
	padding-bottom: 63px;
	text-align: center;
	position: relative;
	@media #{$tablet-up} {
		display: flex;
		text-align: left;
		align-items: center;
		padding-bottom: 40px;
	}
	@media #{$large-up} {
		padding-bottom: 0;
		margin-top: -5px;
	}
	&__figure {
		display: block;
		margin: auto auto 22px;
		max-width: 80%;
		img {
			display: block;
			margin: auto;
			.past-active & {
				visibility: hidden;
			}
		}
		@media #{$tablet-up} {
			width: 43.083333%;
			margin-bottom: -3.4em;
			margin-left: 45px;
			margin-right: 4%;
		}
		@media #{$large-up} {
			width: 27.083333333%;
			margin-left: 60px;
			margin-right: 2.5%;
		} // @media #{$xxlarge-up} {
		// 	margin-right: 7%;
		// 	margin-left: 0;
		// }
		.--past-open & {
			display: none;
		}
	}
	&__content {
		@media #{$tablet-up} {
			width: 65.916666667%;
		}
	}
	&__title {
		color: #ffffff;
		@include chunk;
		font-size: 3.1em;
		line-height: 1.225806452;
		letter-spacing: 0.02px;
		padding: 0 20px;
		margin: 11px 0 9px;
		@media #{$tablet-up} {
			padding: 0;
		}
	}
	&__summary {
		color: rgba(#fff, 0.85);
		@include ft-pro-500;
		p {
			font-size: 2em;
			line-height: 1.6;
			letter-spacing: 0.2px;
			margin: 0 0 1.2em;
			@media #{$large-up} {
				margin: 0 0 1.5em;
			}
			a {
				color: #FFF;
				text-decoration: underline;
				font-weight: bold;
				&:hover,
				&:focus {
					text-decoration: none;
				}
			}
		}
	}
}

.btns+.magazine__summary {
	margin-top: 15px;
}

.past-btn {
	background: transparent;
	border: none;
	color: #fff;
	text-transform: uppercase;
	@include fs-pro-600;
	font-size: 1.4em;
	line-height: 1.285714286;
	margin-bottom: 25px;
	@media #{$tablet-up} {
		position: absolute; // left:0px;
		// bottom:-20px;
		text-align: center;
		top: 50%;
		transform: translateY(-50%);
		left: -16px;
		width: 60px;
		&--close {
			bottom: auto;
			top: 50%;
			transform: translateY(-50%);
			left: auto;
		}
	}
	@media #{$large-up} {
		bottom: auto; // margin-top:-18px;
		margin-top: -31px;
		left: -10px;
		&--close {
			left: -5px;
			margin-top: 0; // top:75px;
		}
	}
	@media #{$xxlarge-up} {
		//left: -98px;
		width: 60px;
		bottom: auto;
		&--close {
			left: -5px;
			margin-top: 0; // top:75px;
		}
	}
	svg {
		display: inline-block;
		vertical-align: middle;
		margin-right: 5px;
		@media #{$tablet-up} {}
		@media #{$large-up} {
			margin: auto auto 9px;
		}
	}
	&__square {
		fill: $dark-red;
		transition: all 0.25s;
	}
	&__stroke {
		fill: #fff;
		fill-opacity: 0;
		stroke: #000;
		stroke-miterlimit: 50;
		stroke-width: 2;
	}
	&__text {
		display: inline-block;
		vertical-align: middle;
	}
	&:hover,
	&:focus {
		text-decoration: underline;
		.past-btn__square {
			fill: #fff;
		}
	}
}

.past {
	list-style: none;
	&__link {
		opacity: 0.4;
		display: block;
		color: #fff;
		transition: 0.25s all;
		&:hover,
		&:focus {
			text-decoration: underline;
			color: #fff;
			opacity: 1;
		}
		@media #{$medium-down} {
			text-align: center;
			img {
				margin-bottom: 20px;
			}
		}
	}
	&__item {
		&.current-past {
			.past__link {
				opacity: 1;
			}
		}
	}
}

.wrap--magazine {
	width: 100%;
	overflow-x: hidden;
}

.magazine-wrap {
	position: relative; //transition:all .25s;
	min-height: 1px;
	&.--past-open {
		// padding-bottom:55px;
		min-height: 500px;
	}
}

.magazine-latest {
	//transition:all .35s ease-in-out .35s;
	opacity: 1;
	.--past-open & {
		//transition-delay: 0;
		opacity: 0;
		pointer-events: none;
	}
}

.bx-wrapper {
	@media #{$large-up} {
		.meta__mag {
			font-size: 1.4em;
			text-align: center;
			display: block;
			margin-top: 17px;
		}
	}
}

.magazine-past {
	position: absolute;
	width: calc(100% + 30px);
	top: 7px; // display: none;
	opacity: 0;
	transition: all 0.25s;
	pointer-events: none;
	min-height: 1px;
	@media #{$large-up} {
		min-height: 500px;
	}
	.--past-open & {
		//  display: block;
		opacity: 1;
		pointer-events: initial;
	}
	&__btns {
		@media #{$tablet-down} {
			width: 50%;
			text-align: center;
			margin: -5px 0;
		}
		@media #{$tablet-up} {
			position: absolute;
			top: 72px;
			height: 221px;
			left: -16px;
		}
		@media #{$large-up} {
			left: -6px; // top:50%;
			// transform:translateY(-50%);
			//left: -92px; //height:284px;
		}
	}
	&__next,
	&__prev {
		position: absolute;
		width: 45px;
		height: 45px;
		background: none;
		z-index: 2; // border-radius: 50%;
		display: none; //border: 2px solid rgba(#969696, .3);
		border: none;
		transition: 0.25s all;
		left: 5px;
		svg {
			fill: #fff;
			&.circle {
				position: absolute;
				top: 0;
				left: 0;
				opacity: 0.3;
				circle {
					stroke: #969696;
					fill: transparent;
				}
			}
			&.circle2 {
				stroke-dasharray: 135px;
				stroke-dashoffset: 135px;
				transition: stroke-dashoffset 600ms ease;
				position: absolute;
				top: 0;
				left: 0;
				circle {
					stroke: $dark-red;
				}
			}
		}
		@media #{$tablet-up} {
			display: block;
		}
		&:hover,
		&:focus {
			// border-color:$dark-red;
			outline: none; // &:before{
			//     content:'';
			//     display: block;
			//     background:#000;
			//     width:32px;
			//     height:23px;
			//     position: absolute;;
			//     top:-3px;
			//     left:-9px;
			// }
			svg.circle2 {
				stroke-dashoffset: 0;
				transform: translate(5, 14);
			}
		}
	}
	&__next {
		svg {
			transform: rotate(-90deg);
		}
	}
	&__prev {
		top: 177px;
		svg {
			transform: rotate(90deg);
			&.circle2 {
				transform: rotate(-90deg);
			}
		}
	}
	&__close {
		background: none;
		border: none;
	}
	.bx-wrapper {
		@media #{$tablet-down} {
			margin: 5px 0 60px 31px;
		}
		@media #{$tablet-up} {
			margin-left: 50px;
		}
		@media #{$xlarge-up} {
			margin-left: 70px;
		}
	}
}

.close-past {
	fill: $dark-red;
}
