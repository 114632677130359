.featured-articles {
	margin-bottom: 26px;
	margin-top: 28px;
	@media #{$large-up} {
		margin-top: 0;
	}
	&.wrap {
		padding-bottom: 26px;
		margin-top: 0;
		z-index: 2;
	}
	&__title {
		border-bottom: 1px solid rgba(#fff, 0.15);
		text-align: center;
		margin: 0;
		@include chunk;
		color: #ffffff;
		font-size: 1.9em;
		font-weight: 400;
		line-height: 1.368421053;
		margin-bottom: 10px;
		padding-bottom: 16px;
		@media #{$large-up} {
			font-size: 2.1em;
			max-width: 328px;
			margin-bottom: 4px;
		}
		&--light {
			background: #000;
			padding: 16px 0;
			margin-bottom: 0;
		}
		&--small {
			font-size: 1.4em;
			border: none;
			margin-bottom: -4px;
			@media #{$tablet-up} {
				font-size: 1.6em;
			}
			@media #{$large-up} {
				font-size: 1.6em;
				text-align: left;
				margin: -6px 0 -2px 10px;
			}
		}
		span {
			display: inline-block;
			vertical-align: middle;
			position: relative;
			&:before,
			&:after {
				content: "•";
				color: #b22a39;
				font-size: 0.7em;
				position: relative;
				display: inline-block;
				vertical-align: middle;
			}
			&:before {
				left: -20px;
			}
			&:after {
				right: -20px;
			}
		}
	}
	&__wrap {
		@media #{$small-med-only} {
			display: flex;
			flex-wrap: wrap;
			align-content: stretch;
			justify-content: space-between;
			.featured {
				width: 48%;
			}
			.popular & {
				display: block;
				.featured__image {
					width: 70px;
				}
				.featured {
					width: 100%;
					max-width: 660px;
				}
			}
		}
	}
	@media #{$large-up} {
		// border: 1px solid rgba(255, 255, 255, 0.1);
		background-color: rgba(0, 0, 0, 0.7);
		padding: 16px 30px 0;
		margin-right: 0;
	} // @media #{$xlarge-up} {
	//     width: 404px;
	//     padding: 16px 7.3% 0 30px;
	// }
	// @media #{$xxlarge-up} {
	//     width: 494px;
	// }
	.main-featured & {
		@media #{$large-up} {
			padding-top: 60px;
		}
	}
}

.featured {
	border-bottom: 1px solid rgba(#fff, 0.15);
	max-width: 328px;
	&:last-child {
		border-bottom: none;
	}
	&__link {
		color: #fff;
		display: flex;
		align-items: center;
		padding: 15px 3px;
		@media #{$large-up} {
			padding: 25px 0;
		}
		.popular & {
			padding: 29px 3px 24px;
		}
		&:hover,
		&:focus {
			.featured__title {
				text-decoration: underline;
			}
		}
	}
	&__title {
		color: #ffffff;
		@include fs-pro-700;
		font-size: 1.5em;
		line-height: 1.53;
		letter-spacing: 0.01px;
		width: 66.23%;
		margin: 0;
		@media #{$large-up} {
			font-size: 1.6em;
		}
	}
	&__image {
		display: block;
		width: 22.15%;
		min-width: 72px;
		margin-right: 27px;
		@media #{$large-up} {
			margin-right: 30px;
		}
		img {
			display: block;
		}
		.popular & {
			width: 70px;
			min-width: 1px;
			margin-right: 21px;
		}
	}
	&__count {
		display: block;
		position: relative;
		width: 70px;
		height: 70px;
		text-align: center;
		color: #fff;
		background: $dark-red;
		@include chunk;
		font-size: 3.2em;
		line-height: 1;
		span {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
			margin-top: 3px;
		}
	}
	&--light {
		border-color: rgba(#969696, 0.25);
		.featured__title,
		.featured__link {
			color: #000;
		}
	}
	&__all {
		color: #000000;
		@include fs-pro-600;
		font-size: 1.4em;
		line-height: 1.857142857;
		display: block;
		margin-top: 20px;
		width: 100%;
		@media #{$large-up} {
			width: auto;
		}
		.--bg-dark & {
			color: #fff;
		}
		&:hover,
		&:focus {
			text-decoration: underline;
		}
		&--border {
			padding-top: 20px;
			margin-top: 0;
			border-top: 1px solid rgba(150, 150, 150, 0.25);
			@media #{$large-down} {
				margin-bottom: 20px;
			}
		}
	}
}

.--bg-dark {
	background: #000;
}

.recently-popular {
	padding: 45px 0 60px;
	background: #fff;
	@media #{$large-up} {
		padding: 64px 0 52px;
	}
}

@media #{$large-up} {
	.popular {
		.ad {
			margin: auto -8px 30px;
		}
	}
}

.title-bleed {
	margin: 0;
	@include chunk;
	font-size: 2.2em;
	position: relative;
	color: #000;
	margin-bottom: 48px;
	&:before {
		content: "";
		width: 21px;
		height: 4px;
		background: $dark-red;
		position: absolute;
		top: 50%;
		transform: translateY(-50%) translateX(-29px);
	}
	@media #{$large-up} {
		font-size: 2em;
		margin-bottom: 29px;
	}
}
