.article-detail {
	padding: 20px 0;
	@media #{$large-up} {
		padding: 55px 0;
	}
}

.detail-hero {
	// background-repeat:no-repeat;
	// background-position:50% 50%;
	// background-size:cover;
	background-image: none !important;
	background: #FFF; // max-height:614px;
	// min-height: 335px;
	&__img,
	img {
		//visibility:hidden;
		display: block;
		max-width: 1260px;
		width: 100%;
		margin: auto;
	}
}

.detail {
	color: darken(#2e3a44, 20%);
	max-width: 896px;
	margin: auto;
	position: relative;
	@media #{$xlarge-up} {
		min-height: 375px;
	}
	&__title {
		color: #000000;
		@include chunk;
		font-size: 3.8em;
		line-height: 1;
		letter-spacing: 0.02px;
		margin: 19px 0px 13px;
		@media #{$large-up} {
			font-size: 5.6em;
			margin: 23px 0px 19px;
		}
		&--large {
			display: none;
			@media #{$tablet-up} {
				display: block;
				;
			}
		}
		&--small {
			@media #{$tablet-up} {
				display: none;
			}
		}
	}
	&__sub {
		color: inherit;
		@include ft-pro-500;
		font-size: 2.1em;
		line-height: 1.619047619;
		letter-spacing: 0.2px;
		border: 1px solid rgba(#969696, .25);
		border-width: 1px 0;
		margin: 0 0 25px;
		padding: 20px 0 26px;
		@media #{$large-up} {
			font-size: 2.3em;
			padding: 16px 0;
			margin: 0 0 20px
		}
		a {
			color: $dark-red;
			&:hover,
			&:focus {
				text-decoration: underline;
			}
		}
		.meta & {
			a {
				color: $dark-red;
				&:hover,
				&:focus {
					text-decoration: underline;
				}
			}
		}
	}
	&__content {
		border-bottom: 1px solid rgba(#969696, .25);
		padding-bottom: 46px;
		margin-bottom: 31px;
		+.pagination {
			//  margin-top:-27px;
			@media #{$large-up} {
				margin: 44px 0 33px
			}
		}
		a {
			// color: $dark-red;
			color: $blue;
			&:hover,
			&:focus {
				text-decoration: underline;
			}
		}
		p {
			color: inherit;
			@include ft-pro-500;
			font-size: 1.9em;
			line-height: 1.631578947;
			letter-spacing: 0.2px;
			strong {
				font-weight: 900;
			}
			@media #{$large-up} {
				font-size: 2em;
				line-height: 1.7;
				margin-bottom: 2em;
			}
		}
		>p.flfc:first-child:first-letter {
			@include chunk;
			color: $dark-red;
			font-size: 5.789473684em;
			text-transform: uppercase;
			float: left;
			line-height: .99;
			margin: -5px 18px -6px 0;
		}
	}
}

.pullquote {
	padding: 0;
	margin: 54px 0 44px;
	&:before {
		content: '';
		width: 186px;
		height: 5px;
		background: $dark-red;
		display: block;
	}
	cite {
		color: #414141;
		@include fs-pro-500;
		font-size: 2.1em;
	}
	p {
		color: #000000;
		@include chunk;
		font-size: 2.4em;
		line-height: 1.666666667;
		margin: 0.8em 0;
		cite {
			font-size: .875em;
		}
	}
	@media #{$large-up} {
		p {
			font-size: 2.4em;
		}
		&--left {
			width: 340px;
			margin: 25px 65px 25px 0;
			float: left;
		}
		&--right {
			width: 340px;
			margin: 25px 0 25px 65px;
			float: right;
		}
		&--full {
			margin: 72px 0 51px 0;
			p {
				font-size: 3.5em;
				line-height: 1.5;
				margin: .95em 0 0.6em;
				cite {
					font-size: .6em;
				}
			}
		}
	}
	@media #{$xlarge-up} {
		&--left {
			margin-left: -101px;
		}
		&--right {
			margin-right: -101px;
		}
		&--full {
			margin-left: -101px;
			width: calc(100% + 215px);
		}
	}
}

.details__related {
	margin-bottom: 43px;
	@media (max-width: 999px) and (min-width: 600px) {
		display: block;
	}
	&__title {
		color: #242424;
		@include chunk;
		font-size: 2.1em;
		line-height: 1.571428571;
		letter-spacing: -0.1px;
		margin: 0 0 14px;
		&:before {
			content: '';
			width: 100%;
			height: 5px;
			background: $dark-red;
			display: block;
			margin-bottom: 18px;
		}
		+.featured {
			border-top: 1px solid rgba(#969696, .25);
			padding-top: 8px;
		}
		@media #{$large-up} {
			margin: 0 0 19px;
		}
	}
	a {
		color: #000;
	}
	.featured {
		margin-bottom: 2px;
		border-color: transparent;
		&:last-child {
			border-bottom: 1px solid rgba(#969696, .25);
			padding-bottom: 8px;
		}
		&__title {
			color: #000;
			@media #{$large-up} {
				width: 70.23%;
			}
		}
		&__image {
			@media #{$large-up} {
				width: 27.15%;
				margin-right: 13px;
				img {
					height: auto;
					width: auto;
				}
			}
		}
		&__link {
			@media #{$large-up} {
				padding: 10px 0;
			}
		}
	}
	&--left {
		@media #{$xlarge-up} {
			width: 340px;
			margin: 25px 65px -1px -101px;
			float: left;
		}
	}
}

.recommended {
	border: 1px solid rgba(#969696, .25);
	border-width: 1px 0;
	margin: 38px 0 50px;
	padding: 0;
	@media #{$large-up} {
		margin: 38px 0 40px;
	}
	p {
		@include ft-pro-300-i;
		font-size: 2.4em;
		color: #585f69;
		margin: 0.7em 0;
		@media #{$large-up} {
			font-size: 2.4em;
			margin-bottom: 0.6em;
		}
		a {
			@include chunk;
			color: #000;
			font-size: .958333333em;
			line-height: 1.47826087;
			font-style: normal;
			display: block;
			margin-top: 10px;
			@media #{$large-up} {
				display: inline;
			}
		}
	}
}

.post-pagination {
	border: 5px solid rgba(#969696, .25);
	border-width: 5px 0;
	margin-bottom: 40px;
	&.--bg-light {
		padding: 0;
		@media #{$medium-up} {
			padding: 0;
		}
	}
}

.meta--detail {
	margin-bottom: 41px;
	@media #{$large-up} {
		margin-bottom: 45px;
		.meta__date {
			font-size: 1.9em;
		}
	}
	.meta__row {
		@media #{$tablet-down} {
			margin-bottom: 20px;
		}
	}
	.meta__author {
		font-size: 1.8em;
		a {
			color: $dark-red;
			.social {
				font-size: 1.277777778em;
				position: relative;
				top: 2px;
				left: 5px;
				margin-right: 20px;
			}
		}
		&__divide {
			margin: auto 15px auto 5px;
		}
	}
}
