.category{
    text-align: center;
    padding-bottom:44px;
    @media #{$tablet-up}{
        text-align: left;
    }
    &__title{
        color: #ffffff;
        @include ft-pro-600;
        font-size:3.1em;
        line-height: 1.870967742;
        letter-spacing: 0.02px;

        &:after{
            content:'';
            width:27px;
            height:2px;
            background:$dark-red;
            margin:auto;
            display: block;
        }

        @media #{$tablet-up}{
            font-size:5em;
            line-height: 1.16;

            &:after{
                margin:5px 0 -15px 0;
            }
        }
    }

    p{
        @include ft-pro-500;
        color: #d9d9d9;
        font-size:2em;
        line-height: 1.6;
        letter-spacing: 0.2px;
        margin-bottom:26px;
        a{
            color:#FFF;
            text-decoration: underline;
            &:hover, &:focus{
                text-decoration: none;
            }
        }
    }
}