.meta {
	letter-spacing: .2px;
	&--featured {
		width: 66.3%;
		margin: -15px 0 0 102px;
		padding-bottom: 25px;
	}
	&--article--img {
		margin-left: 20px; //margin-top: -28px;
		margin-top: -26px;
		position: absolute;
		z-index: 2;
		@media #{$large-up} {
			.article-grid__main & {
				margin-left: 32px; //margin-top: -35px;
				margin-top: -26px;
			}
		}
	}
	&__author {
		@include ft-pro-600;
		font-size: 1.6em;
		@media #{$medium-up} {
			font-size: 1.7em;
		}
		a {
			margin-right: 10px;
		}
		.article--flex & {
			@media #{$medium-up} {
				font-size: 1.8em;
			}
		}
	}
	a {
		text-decoration: none;
		@include fs-pro-600;
		font-size: .9375em;
		color: #FFF;
		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}
	&__date {
		color: #414141;
		@include fs-pro-600;
		font-size: 1.4em;
		font-style: normal;
		letter-spacing: 0.2px;
		@media #{$medium-up} {
			font-size: 1.7em;
		}
	}
	&__divide {
		content: '';
		height: 21px;
		width: 1px;
		background: #969696;
		opacity: 0.5;
		display: inline-block;
		vertical-align: middle;
		margin: 0 8px;
	}
	&__original {
		display: block;
		margin: 13px 0;
		color: #414141;
		@include fs-pro-500-i;
		font-size: 1.3em;
		letter-spacing: 0.2px;
		@media #{$large-up} {
			font-size: 1.2em;
			display: inline-block;
			margin: 11px 9px 11px 9px;
			+.meta__divide {
				display: inline-block;
			}
		}
		a {
			color: #000;
			text-decoration: underline;
			&:hover,
			&:focus {
				text-decoration: none;
			}
		}
		&:after {
			content: '';
			height: 1px;
			display: block;
		}
		+.meta__divide {
			display: none;
		}
	}
	&__categories {
		@include ft-pro-300-i;
		font-size: 1.6em;
		color: #585f69;
		@media #{$large-up} {
			font-size: 1.9em;
		}
		a {
			color: #414141;
			@include fs-pro-600;
			font-size: .9375em;
			transition: all .25s;
			display: inline-block;
			&:after {
				content: ',';
				display: inline-block;
				margin-right: 8px;
			}
			&:last-child {
				&:after {
					display: none;
				}
			}
			&:hover,
			&:focus {
				color: $dark-red;
			}
		}
	}
	&__mag {
		font-family: "Freight Sans Pro";
		@include fs-pro-600;
		font-size: 1.5em;
		line-height: 1.733333333;
		text-transform: uppercase;
		@media #{$large-up} {
			font-size: 1.6em;
		}
	}
}
